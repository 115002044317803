import { FormControl} from "@mui/material";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";

export function InputTags(props) {
  const { node, data, change, index } = props;
  const { t } = useTranslation();

  const setChange = (event, values) => {
    let updateData = [...data];
    let filter;

    filter = updateData.filter((n) => {
      return n.name === node.name;
    });
    if (filter[0]) {
      filter[0].value = values;
    }
    if (index) {
      change([...updateData], index);
    } else {
      change([...updateData]);
    }
  };

  
  const testnumber = (evt, val = '') => {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    if (theEvent.ctrlKey && key === 86) {
      theEvent.returnValue = true;
      return true;
    }
    if (node.inputType === "number") {
      if (!theEvent.shiftKey && ((key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key === 8 || key === 46 || key === 37 || key === 39 || key === 9)) {
        theEvent.returnValue = true;
        console.log("theEvent", theEvent)
      } else {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
        return false;
      }
    }
    else if (node.inputType === "float") {
      if (!theEvent.shiftKey && ((key >= 48 && key <= 57) || key === 8 || key === 46 || key === 190 || key === 110 || key === 37 || key === 39 || key === 9)) {
        if ((val === '' || val.indexOf(".") !== -1) && (key === 190 || key === 110)) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
          return false;
        } else
          return true;
      } else {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };
  console.log("node.value",node.value)
  return (
    <div className="p-2">
      <FormControl disabled={node?.disabled} className="w-full">
          <Autocomplete
            multiple={node?.multiple}
            id={node.title}
            disabled={node?.disabled}
            options={node.value.map((option) => option)}
            defaultValue={node.value}
            freeSolo={true}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                // return option.length === 4 &&
                return <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            })
            }
            onChange={setChange}
          
            renderInput={(params) => (
              
              <TextField
                {...params}
                disabled={node?.disabled}
                onKeyDown={(e) => {
                  testnumber(e)
                }}
                error={node?.disabled ? false : node.error}
                label={t(node.title) + (node.required ? "*" : "")}
                placeholder={t(node.title)}
              />
            )}
          />
      </FormControl>
    </div>
  );
}