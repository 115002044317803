import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RadioButtonsGroup = (props) => {
  const { node, data, change } = props;

  const setChange = (value) => {
    let updateData = [...data];
    let filter;

    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    if (filter[0]) {
      filter[0].value = value;
      // Disable Checkbox by passing reference
      if (filter[0].reference) {
        filter[0]?.reference.items.map((item) => {
          let refData = updateData.find((n) => {
            return n.name === item;
          });
          if (refData) {
            if (filter[0].value === "DateRange") {
              refData[filter[0].reference.action] = false;
            } else if (filter[0].value !== "DateRange") {
              refData[filter[0].reference.action] = true;
            }
          }
        });
      }
      //
    }

    change([...updateData]);
  };
console.log("node-radio", node);
  return (
    <div className="row" style={{ display: "flex", width: "100%" }}>
      <FormControl error={node.error}>
        {node.title !== "" && (
          <FormLabel
            id="demo-radio-buttons-group-label"
            className="pl-10 pt-20"
          >
            {node.title}
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          row
          className="pl-1.5"
        >
          {node.childrens.map((n) => {
            return (
              <FormControlLabel
                key={n.uuid}
                value={n.value}
                onChange={(e) => setChange(e.target.value)}
                control={
                  <Radio
                    name={node.name}
                    disabled={node.disabled}
                    sx={{
                      "&, &.Mui-checked": {
                        color: "#F78F1E",
                      },
                    }}
                  />
                }
                checked={node.value === n.value ? true : false}
                label={n.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioButtonsGroup;
