import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "../src/toolkit/store";
import { ThemeProvider } from "./theme/themeContext";
import { AuthProvider } from "./navigation/auth/useAuth";
import RouterConfig from "./navigation/router.config";
import { AppProvider } from "./appContext";
import GetTenant from "./containers/auth/GetTenant";
import AuthUser from "./containers/auth/AuthUser";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Faro from "./services/faro";
import Dashboard from "./containers/dashboard";
import { AuthClient } from "./containers/authv2/authClient";
import AuthInterceptor from "./containers/authv2/authInterceptor";
import { useEffect } from "react";
import Interceptor from "./services/interceptor";
import axios from "axios";

function App() {
  // window.localStorage.setItem(
  //   "currentAccount",
  //   "d6be44f9-a3e9-4d18-b487-ae7139b11ea2"
  // );

  return (
    <div data-testid="sonu" className="App bg-[rgba(0,0,0,0.20)]">
      {/* <Dashboard></Dashboard> */}
      <Provider store={store}>
        <ReactNotifications />
        <Faro>
          <BrowserRouter>
            {/* <Interceptor > */}
            <ThemeProvider>
              <AuthClient>
                <AuthInterceptor>
                  <AuthProvider>
                    {/* <GetTenant>
                  <AuthUser> */}
                    <AppProvider>
                      <RouterConfig />
                    </AppProvider>
                    {/* </AuthUser>
                </GetTenant> */}
                  </AuthProvider>
                </AuthInterceptor>
              </AuthClient>
            </ThemeProvider>
            {/* </Interceptor> */}
          </BrowserRouter>
        </Faro>
      </Provider>
    </div>
  );
}

export default App;

{
  /* 503 */
}
