import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";


function SearchBar(props: any) {
  const {
    searchtext,
    setSearchText,
    placeholder,
    handleSearch,
    handleKeyPress,
    setHandleKeyPress,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const onhandleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      // console.log("eventsss", event.key, event.target.value);
      setHandleKeyPress(true);
      handleSearch(event.target.value?.trimStart());
      setSearchText(event.target.value?.trimStart());
    }
  };
  const handleSrch = (e: any) => {
    setSearchTerm(e.target.value?.trimStart())
    setSearchText(e.target.value?.trimStart())
  };
  // console.log("handleKeyPress", handleKeyPress);
  return (
    <div className="relative w-full h-[48px]">
      <div className="">
        <div className="absolute right-3  top-[6px] cursor-pointer">
          {searchTerm.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon onClick={() => {
              setSearchTerm('')
              setSearchText('')
              if(handleSearch){
                handleSearch('')
              }else{
                onhandleKeyPress('')
              }
            }} />
          )}
        </div>
        <input
          type="text"
          className="p-2 border border-[#aaa] w-full rounded-md"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) =>
            handleKeyPress ? setSearchTerm(e.target.value?.trimStart()) : handleSrch(e)
          }
          onKeyDown={(e) => (handleKeyPress ? onhandleKeyPress(e) : null)}
        />
      </div>
    </div>
  );
}
export default SearchBar;
