import FadeIn from "react-fade-in/lib/FadeIn";
import SearchBar from "../../../atom/search";
import { useState } from "react";
import AgentInfoCard from "../queueMonitor/agentInfoCard";

function UserPanel() {
  const [sort, setSort] = useState<string>("");
  const [open, setOpen] = useState<boolean>(true);
  const [searchtext, setSearchText] = useState<any>("");
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(false);
  const onHandleSort = (sortBy: string) => {
    setSort(sortBy);
  };

  const count: any = [];
  for (let i = 0; i <= 50; i++) {
    count.push(i);
  }
  return (
    <div data-testid="userPanel" className=" h-full py-3 flex relative gap-2.5">
      <div className=" h-[calc(100vh-125px)]  w-[calc(100vw-126px)] rounded-md bg-[#FFFFFF]">
        <FadeIn>
          {/* Top */}
          <div className="flex justify-between py-5 px-8">
            <p className="font-bold">User Panel</p>
            <div className="w-[22%]">
              <SearchBar
                placeholder="Search by name/last name"
                // searchtext={searchtext}
                // setSearchText={setSearchText}
                // handleKeyPress={handleKeyPress}
                // setHandleKeyPress={setHandleKeyPress}
              />
            </div>
          </div>
          {/*  */}
          <div className="flex gap-2 px-8">
            <p>Sort by</p>
            <div
              className={`rounded-lg w-[110px] h-min-[30px] h-full break-words ${
                sort === "0"
                  ? "text-[#AB5D0A] font-bold bg-[#FEF4E9]"
                  : "bg-[#EBEBEB80]"
              } text-center bg-[#EBEBEB80] cursor-pointer hover:bg-[#FEF4E9] hover:text-[#AB5D0A] hover:font-bold`}
              onClick={() => onHandleSort("0")}
            >
              Extension
            </div>
            <div
              className={`rounded-lg w-[90px] h-min-[30px] h-full break-words text-center ${
                sort === "1"
                  ? "text-[#AB5D0A] font-bold bg-[#FEF4E9]"
                  : "bg-[#EBEBEB80]"
              } cursor-pointer hover:bg-[#FEF4E9] hover:text-[#AB5D0A] hover:font-bold`}
              onClick={() => onHandleSort("1")}
            >
              First Name
            </div>
            <div
              className={`rounded-lg w-[90px] h-min-[30px] h-full break-words text-center ${
                sort === "2"
                  ? "text-[#AB5D0A] font-bold bg-[#FEF4E9]"
                  : "bg-[#EBEBEB80]"
              } cursor-pointer hover:bg-[#FEF4E9] hover:text-[#AB5D0A] hover:font-bold`}
              onClick={() => onHandleSort("2")}
            >
              Last Name
            </div>
          </div>
          {/* main body */}
          <div className="w-full mt-4 px-8 grid xl:grid-cols-6 gap-2 md:grid-cols-4 sm:grid-cols-1 overflow-auto max-h-[calc(100vh-270px)]">
            {count?.map((node: any) => {
              return (
                <div key={node}>
                  <AgentInfoCard open={open} setOpen={setOpen} />
                </div>
              );
            })}
          </div>
          {/*  */}
        </FadeIn>
      </div>
    </div>
  );
}

export default UserPanel;
