import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  data: [];
}

const initialState: MenuState = {
  data: [],
};

const provisonedUserSlice = createSlice({
  name: "provisionedData",
  initialState,
  reducers: {
    setProvisionData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      console.log(state, "state");
    },
  },
});

export const { setProvisionData } = provisonedUserSlice.actions;
export default provisonedUserSlice.reducer;
