import React from "react";
import { constQueueInfo } from "../../../config/constants";

function QueueInfo() {
  const queueInfo = constQueueInfo;
  return (
    <div data-testid="queueInfo">
      <div className="grid grid-cols-6 p-3">
        {queueInfo?.map((node: any, index: number) => {
          return (
            <div
              key={node}
              className={`text-center  ${
                index === 5 ? "" : "border-r-[1px] border-[#0000001F]"
              }`}
            >
              <p className="flex justify-center">
                <div className="w-[45px] h-[45px] bg-[#E6E9F7] flex justify-center items-center rounded-lg">
                  {node.icon}
                </div>
              </p>
              <p className="font-extrabold  block mt-1 break-words">
                {node.number}
              </p>
              <p className="text-[#888888] block mt-0 break-words ">
                {node.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default QueueInfo;
