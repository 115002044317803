import { faro } from '@grafana/faro-core';
import { getENVData } from '../config/environment';
import store from '../toolkit/store';

/*
* This Function is used for Updating Childrens
*/
const env: any = getENVData();
export function addChildrensToMapData(mapData: any, items: any, key: string) {
  let result = mapData.find((node: any) => {
    return node.name === key
  });
  if (result) {
    result.childrens = items;
    console.log(result, "resutt")
  }
  return mapData;
}
/**
 * Appends the given items to the children of the node with the specified key in the map data.
 *
 * @param {any} mapData - the map data containing nodes
 * @param {any} items - the items to be appended to the children
 * @param {string} key - the key to identify the node whose children will be appended
 * @return {any} the updated map data
 */
export function appendChildrensToMapData(mapData: any, items: any, key: string) {
  let result = mapData.find((node: any) => node.name === key);
  if (result) {
    result.childrens.push(...items);
  }
  return mapData;
}
/**
 * Updates the value of a key in an object within an array of objects.
 *
 * @param {any} mapData - the array of objects to search through
 * @param {string} objectKey - the key to match against the 'name' property in the objects
 * @param {string} key - the key in the object to update
 * @param {any} value - the new value to set for the specified key
 * @return {any} the updated array of objects
 */
export function updateObjectKeyValue(mapData: any, objectKey: string, key: string, value: any) {
  // debugger
  const data = mapData.find((node: any) => {
    return node.name === objectKey
  })
  if (data) {
    data[key] = value;
  }
  return mapData;
}
export function appendLoopDataToMapData(mapData: any, items: any) {
  let result: any = [];
  items.forEach((item: any) => {
    let copy = JSON.parse(JSON.stringify(mapData));
    copy.forEach((node: any) => {
      Object.keys(item).forEach((key) => {
        if (node.name === key) {
          node.value = key === 'id' ? [item[key]] : item[key];
          node.error = false;
          node.disabled = false;
        }
      });
    });
    result.push(copy)
  })
  return result
}
export function actualDataToMapData(mapData: any, item: any) {
  console.log("mapdataaaa", mapData, item);

  mapData.forEach((node: any) => {
    console.log(node, item, "nodeeeeee")
    const exp = node.name ? node?.name.split('.') : '';
    Object.keys(item).forEach((key) => {
      console.log(Object.keys(item), key, "checkokkk")

      if (node.name === key) {
        console.log(node.name, key, "checkokkk")
        node.value = node.name === 'fallthrough' ? [item[key]] : item[key] !== null ? item[key] : '';
        node.error = false;
        // node.disabled = false;
      } else if (node.type === 'password') {
        node.showHint = false;
      } else if (exp.length === 2 && exp[0] === key) {
        node.value = item[exp[0]][exp[1]];
      } else if (exp.length === 3 && exp[0] === key) {
        node.value = item[exp[0]][exp[1]][exp[2]];
      }
      if (node?.reference && node?.reference?.items) {
        node?.reference?.items.forEach((ref: any) => {
          let refData = mapData.find((refMap: any) => {
            return refMap.name === ref
          })
          if (refData) {
            if (node.reference.condition.operator === "=") {
              refData[node.reference.action] = node.value
            } else if (node.reference.condition.operator === "!=") {
              refData[node.reference.action] = !node.value
            }
          }
        })
      }
    });
  });
  mapData.forEach((node: any) => {
    if (node.reference) {
      let refData: any = mapData.find((n: any) => {
        return n.name === node?.reference.name;
      });
      if (refData) {
        if (node.reference.condition.operator === "=") {
          refData[node.reference.action] = node.value;
        }
      }
    }
  });
  return mapData;
}
export function addAKeyValueInListItems(mapData: any, key: string, value: any) {
  mapData.forEach((node: any) => {
    node[key] = node[value] ? node[value] : value;
  })
  return mapData;
}
export function addAKeyValueFromRefListItems(mapData: any, key: string, ref: any) {
  mapData.forEach((node: any) => {
    node[key] = node[ref[0]] + " " + (ref[1] ? node[ref[1]] : '');
  })
  return mapData;
}
export function secondsToHms(d: any) {
  d = Number(d);

  const h = Math.floor(d / 3600).toString().padStart(2, '0'),

    m = Math.floor(d % 3600 / 60).toString().padStart(2, '0'),

    s = Math.floor(d % 60).toString().padStart(2, '0');

  let adjust = ''

  if (h === '00') {

    if (m === '00') {

      adjust = `0:${s}`

    } else {

      adjust = `${m}:${s}`

    }

  } else {

    adjust = `${h}:${m}:${s}`

  }

  return adjust;

}

export function secondsToHHMMSS(seconds: any) {
  // Here all thetime will be in hh:mm:ss format
  let hours: any = Math.floor(seconds / 3600);
  let minutes: any = Math.floor((seconds % 3600) / 60);
  let remainingSeconds: any = seconds % 60;

  hours = String(hours).padStart(2, "0");
  minutes = String(minutes).padStart(2, "0");
  remainingSeconds = String(remainingSeconds).padStart(2, "0");

  return `${hours}:${minutes}:${remainingSeconds}`;
}
export function dashboardRandomNumber(maxLimit = 5) {

  let rand = Math.random() * maxLimit;

  rand = Math.floor(rand);

  return rand;

}
export function getTimeFromstring(time: string, date: string, name: string) {
  console.log(time, date, 'getTimeFromstring');
  var hours: any
  var minutes: any
  var seconds: any
  const newDate = date.split('T')[0]
  var formattedDate: any = ''
  if ((time === '' || time === null) && newDate !== '') {
    if (name === 'start_time') {
      formattedDate = new Date(newDate).setHours(0, 0, 0, 0);
    } else if (name === 'end_time') {
      formattedDate = new Date(newDate).setHours(23, 59, 59, 59);
    }
  } else {
    hours = new Date(time).getHours();
    minutes = new Date(time).getMinutes();
    seconds = new Date(time).getSeconds();
    formattedDate = new Date(newDate).setHours(hours, minutes, seconds);
  }


  return formattedDate
}
export function randomNumber() {
  const crypto = window.crypto;
  let array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}
interface Click {
  type: "click"
  message: string,
}
// interface Payload {
//   payload: 
// }
interface ApiCall {
  type: "api_call_response" | "api_call_request"
  message: {
    status: any,
    config: any
  },
}
type FaroLog = Click | ApiCall

export function FaroLog(data: FaroLog) {
  // debugger

  if (env?.config?.faro?.url) {
    var context: any
    if (data.type === "api_call_response") {
      const result = {
        rest_series: Math.floor(data.message.status / 100) as 2 | 3 | 4 | 5,
        rest_comments: `API ${data.message.status} ${data.message.config.method} ${data.message.config.url}`,
        rest_error_code: data.message.status,
        rest_exc_time: new Date().toTimeString(),
      }
      context = {
        context: {
          rest_series: result?.rest_series.toString() || '',
          rest_error_code: result.rest_error_code.toString() || '',
          rest_comments: result.rest_comments.toString() || '',
          rest_exc_time: result.rest_exc_time.toString() || '',
          payload: JSON.stringify(data.message),
          type: data.type,
        }
      }
    } else if (data.type === "api_call_request") {
      context = {
        context: {
          payload: JSON.stringify(data.message),
          type: data.type,
        }
      }
    } else {
      context = {
        context: {
          payload: data.message.toString(),
          type: data.type,
        }
      }
    }
    console.log("contextcontextcontext", context)
    faro.api.pushLog([JSON.stringify(data.message)], context);
  }
}


export function isSuccessCode(statusCode: number, successDigit: number = 2): boolean {
  return String(statusCode).startsWith(String(successDigit));
}
export function hasPermission(group: string, permission: string, permissions: any): boolean {
  return permissions?.[group]?.includes(permission) ?? false;
}
export function timeStampToTime(timestamp: number): string {
  var date = new Date(timestamp)
  return date.toLocaleTimeString("en-US")
}
export function timeStampToDateTime(timestamp: number): string {
  var date = new Date(timestamp)
  return date.toLocaleString("en-US")
}

export async function downloadFileFromURL(url: string, fileName: string, accessToken: string): Promise<boolean> {
  const token = accessToken;
  var ext = url.split('.').pop()
  return await fetch(url, {
    headers: { Authorization: `Bearer ${token}` }
  }).then((response: any) => {
    if (response.ok) {
      return response.blob().then((b: any) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(b);
        link.target = "_blank";
        const date: any = new Date()
        link.setAttribute("download", `${fileName} ${date.toLocaleString("en-US", { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}.${ext}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true
      }).catch((error: any) => {
        console.log("Something went Wrong", error);
        return false
      });
    } else {
      return false
    }

  });

}
