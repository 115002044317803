import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  tenantNames: [];
}

const initialState: CallState = {
  tenantNames: [],
};

const tenantsList = createSlice({
  name: "tenants", // Use a different name for the slice
  initialState,
  reducers: {
    tenantsMenu: (state, action: PayloadAction<any>) => {
      state.tenantNames = action.payload;
      console.log("state.activeMenu", state.tenantNames);
    },
  },
});

export const { tenantsMenu } = tenantsList.actions;
export default tenantsList.reducer;
