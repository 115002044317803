import { remove, put, post, get } from "./api.service";
import { APIResponse } from "./interfaces";
import store from '../toolkit/store';

export const getToken = () => {
    const state: any = store.getState()
    if (state.Simulation.simulate?.uuid) {
        return state.Simulation.simulate.uuid
    } else {
        return state.Permissions.accountId
    }
}
export const getTelcoId = () => {
    const state: any = store.getState()
    return state.Permissions.accountId
}
/**
 * Retrieves a client from the API using the provided email address.
 *
 * @param {string} email - The email address of the client.
 * @return {Promise<APIResponse>} A promise that resolves to the API response containing the client data.
 */
export async function getClientFromEmail(email: string): Promise<APIResponse> {
    const url = `email/clients?email=${email}`;
    return get(url, { skipTenant: true });
}
export async function tryKeyCloakLogin(options: any): Promise<APIResponse> {
    const url = `realms/${options.realm}/protocol/openid-connect/token`;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const payload = new URLSearchParams({
        username: options.username,
        password: options.password,
        grant_type: options.grant_type,
        client_id: options.sname
    })
    return post(url, payload, { resourceURL: 'keyCloak' }, headers);
}
export async function tryKeyCloakRefresh(options: any): Promise<APIResponse> {
    const url = `realms/${options.realm}/protocol/openid-connect/token`;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const payload = new URLSearchParams({
        refresh_token: options.refresh_token,
        grant_type: options.grant_type,
        client_id: options.sname
    })
    return post(url, payload, { resourceURL: 'keyCloak' }, headers);
}
export async function tryKeyCloakResetPassword(options: { id: string, realm: string, type: "password", temporary: false, password: string }): Promise<APIResponse> {
    const url = `realms/${options.realm}/users/${options.id}/reset-password`;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const payload = new URLSearchParams({
        type: options.type,
        temporary: options.temporary + '',
        value: options.password
    })
    return post(url, payload, { resourceURL: 'keyCloak' }, headers);
}
export async function tryKeyCloakLogOut(options: any): Promise<APIResponse> {
    const url = `realms/${options.realm}/protocol/openid-connect/logout`;
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const payload = new URLSearchParams({
        refresh_token: options.refresh_token,
        client_id: options.sname
    })
    return post(url, payload, { resourceURL: 'keyCloak' }, headers);
}
export async function getChildrens(payload: any, skip = false) {
    const url = `getChildrens`
    const result = post(url, payload, { skipHeader: skip })
    return result;
}
export async function getTelco(uuid: string) {
    const url = `getTelco/${uuid}`
    const result = post(url, {})
    return result;
}
export async function verifyClient(payload: any) {
    const url = `verifyClient?clientId=${payload}`
    const result = get(url, {})
    return result;
}
export async function deleteAccount(uuid: string) {
    const url = `deleteTelco/${uuid}`
    const result = remove(url)
    return result;
}
export async function updateAccount(payload: any) {
    const url = `updateTelco/${payload.uuid}`
    delete payload.uuid
    const result = post(url, payload)
    return result;
}
export async function putAccount(payload: any) {
    const url = `putTelco`
    delete payload.uuid
    const result = put(url, payload)
    return result;
}
export async function addAccount(payload: any) {
    const url = `addTelco`
    const result = put(url, payload)
    return result;
}
export async function getTenant(uuid: string) {
    const url = `getTenant/${uuid}`
    const result = post(url, {})
    return result;
}
export async function deleteTenant(uuid: string) {
    const url = `deleteTenant/${uuid}`
    const result = remove(url)
    return result;
}
export async function updateTenant(payload: any, options: any = { skipHeader: false }) {
    const url = `updateTenant/${payload.uuid}`
    delete payload.uuid
    const result = post(url, payload, options)
    return result;
}
export async function putTenant(payload: any) {
    const url = `putTenant`
    delete payload.uuid
    const result = put(url, payload)
    return result;
}
export async function getUserPermission() {
    const url = `permissions`
    const result = get(url, { skipTenant: true })
    return result;
}

export async function getTenantsList(payload: any) {
    const url = `telco/${getToken()}/sil_tenants`
    const result = get(url, payload)
    return result;
}

export async function getTenantPermission(tenantId: string = '68cd7f1d-e6ba-4718-a289-264ba7767dca') {
    // https://vaspian-dev.vaspian.com/v2/permissions?tenant=
    const url = `permissions?tenant=${tenantId}&role=legacy_tenant_admin`
    const result = get(url)
    return result;
}
export async function getAppServerTenants(payload: any) {
    const url = `telco/${getTelcoId()}/tenants`
    const result = get(url, payload)
    return result;
}
export async function addTenantsToAppServer(payload: any) {
    const url = `tenants`
    const result = post(url, JSON.parse(JSON.stringify(payload)))
    return result;
}
export async function provisionUser(payload: any, uuid: string) {
    const url = `tenant/${uuid}/tenant_users`
    const result = post(url, payload)
    return result;
}
export async function getProvisionData(payload: { limit: number, pageId: number, sort_column: string, sort_direction: string, searchtext: string }) {
    let url: any = ""
    if ((payload.sort_column && payload.sort_direction) === "") {
        url = `telco/${getToken()}/tenant_users/fetch?limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    else {
        url = `telco/${getToken()}/tenant_users/fetch?limit=${payload.limit}&pageId=${payload.pageId}&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`

    }
    const result = get(url, {})
    return result;
}

export async function getTenantUsers(payload: { limit: number, pageId: number, sort_column: string, sort_direction: string, searchtext: string }, tenant_uuid: any) {
    let url: any = ""
    if ((payload.sort_column && payload.sort_direction) === "") {
        url = `tenant/${tenant_uuid}/tenant_users/fetch?limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    else {
        url = `tenant/${tenant_uuid}/tenant_users/fetch?limit=${payload.limit}&pageId=${payload.pageId}&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`

    }
    const result = get(url, {})
    return result;
}

export async function getProvisionUserData(payload: any, tenant_uuid: string) {
    const url = `tenant/tenant_uuid/tenant_users`
    const result = get(url, payload)
    return result;
}

export async function getDids(payload: { limit: number, pageId: number, sort_column: string, sort_direction: string, searchtext: string }, tenant_uuid: any) {
    let url: any = ""
    if ((payload.sort_column && payload.sort_direction) === "") {

        url = `vrsa/tenants/${tenant_uuid}/dids/fetch?limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    else {
        url = `vrsa/tenants/${tenant_uuid}/dids/fetch?limit=${payload.limit}&pageId=${payload.pageId}&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    const result = get(url, {})
    return result;
}

export async function editProvisionUser(payload: any, tenant_uuid: any, tenantuser_id: any) {
    const url = `tenant/${tenant_uuid}/tenant_users/${tenantuser_id}`
    const result = put(url, payload)
    return result;
}

export async function deleteProvisionuser(payload: any, tenant_uuid: any, tenantuser_id: any) {
    const url = `tenant/${tenant_uuid}/tenant_users/${tenantuser_id}`
    const result = remove(url, payload)
    return result;
}
export async function downloadRecordings(payload: any) {
    const url = `cr/tenants/${getToken()}/call_recordings/download`
    const result = post(url, payload)
    return result;
}

export async function getCallRecords(payload: any) {
    const url = `cr/tenants/${getToken()}/call_recordings`
    return post(url, payload);
}
export async function getLegacyCallRecordsForDownload(payload: any) {
    // POST https://vaspian-dev.vaspian.com/v2/legacy/tenants/a900ff27-0d1a-4abf-aa94-82f2d69d3f05/legacy_calls/export
    const url = `legacy/tenants/${getToken()}/legacy_calls/export`
    return post(url, payload);
}

export async function getCDRReportRecordsForDownload(payload: any) {
    // POST https://vaspian-dev.vaspian.com/v2/legacy/tenants/a900ff27-0d1a-4abf-aa94-82f2d69d3f05/legacy_calls/export
    const url = `cr/tenants/${getToken()}/call_reports/export`
    return post(url, payload);
}

export async function getLegacyCDRRecordsForDownload(payload: any) {
    const url = `cr/tenants/${getToken()}/call_recordings/export`
    return post(url, payload);
}

export async function AddSearchNameForFilter(payload: any) {
    const url = `cr/tenants/${getToken()}/rec_filters`
    return post(url, payload);
}

export async function getSearchNameForFilter(payload: any) {
    const url = `cr/tenants/${getToken()}/rec_filters`
    const result = get(url, payload)
    return result;
}

export async function getSearchFilterResults(payload: any, uuid: any) {
    const url = `legacy/tenants/${getToken()}/legacy_call_filters/${uuid}`
    const result = get(url, payload)
    return result;
}

export async function removeSearchFilterResults(payload: any, uuid: any) {
    const url = `cr/tenants/${getToken()}/rec_filters/${uuid}`
    const result = remove(url, payload)
    return result;
}

export async function getLegacyCallReport(payload: any) {
    const url = `legacy/tenants/${getToken()}/legacy_calls`
    const result = post(url, payload)
    return result;
}

export async function getCallInfo(payload: any, uuid: any, timestamp: any) {
    const url = `legacy/tenants/${getToken()}/legacy_calls/${uuid}/segments?origination_timestamp=${timestamp}`
    const result = get(url, payload)
    return result;
}

export async function getLegacyReportsCallInfo(payload: any, uuid: any, timestamp: any) {
    const url = `cr/tenants/${getToken()}/legacy_calls/${uuid}/segments?origination_timestamp=${timestamp}`
    const result = get(url, payload)
    return result;
}
export async function getCdrReportsInfo(payload: any, uuid: any, timestamp: any) {
    const url = `cdr/tenants/${getToken()}/cdrs/${uuid}/segments?origination_ts=${timestamp}`
    const result = get(url, payload)
    return result;
}

// export async function getCDRCalls(payload: any) {
//     const url = `cdr/tenants/${getToken()}/cdrs`
//     const result = post(url, payload)
//     return result;
// }
export async function getCDRCalls(payload: any) {
    const url = `cr/tenants/${getToken()}/call_reports`
    const result = post(url, payload)
    return result;
}
export async function getAllTenants(payload: { limit: number, pageId: number, sort_column: string, sort_direction: string, searchtext: string }, skip = false) {
    let url: any = ""
    if ((payload.sort_column && payload.sort_direction) === "") {
        url = `telco/${getToken()}/tenants/fetch?limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    else {
        url = `telco/${getToken()}/tenants/fetch?limit=${payload.limit}&pageId=${payload.pageId}&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }

    const result = get(url, {})
    return result;
}

export async function migrateTenant(payload: any) {
    const url = `telco/${getToken()}/tenants`
    const result = post(url, payload)
    return result;
}

export async function getTenantUserDetails(payload: any) {
    const url = `user_details`
    const result = get(url, payload)
    return result;
}

export async function getUserRoles(payload: any, id: any) {
    const url = `tenant/${id}/roles`
    const result = get(url, payload)
    return result;
}
export async function resetPassword(payload: any, userId: any) {
    const url = `users/${userId}/reset_password`
    const result = post(url, payload)
    return result;
}

export async function getLegacyCallReportSavedSearches(payload: any) {
    const url = `legacy/tenants/${getToken()}/legacy_calls_filters`
    const result = get(url, payload)
    return result;
}

export async function AddSearchNameForLegacyCallReports(payload: any) {
    const url = `legacy/tenants/${getToken()}/legacy_calls_filters`
    const result = post(url, payload)
    return result;
}

export async function getParticularFilterResultsLegacyCallReports(payload: any, id: string) {
    const url = `legacy/tenants/${getToken()}/legacy_calls_filters/${id}`
    const result = get(url, payload)
    return result;
}

export async function removeFilterSearchLegacyCallReports(payload: any, id: string) {
    const url = `legacy/tenants/${getToken()}/legacy_calls_filters/${id}`
    const result = remove(url, payload)
    return result;
}

export async function AddSearchNameForCallReports(payload: any) {
    const url = `cr/tenants/${getToken()}/call_filters`
    const result = post(url, payload)
    return result;
}

export async function getSavedSearchesLegacyCallReports(payload: any) {
    const url = `cr/tenants/${getToken()}/call_filters`
    const result = get(url, payload)
    return result;
}

export async function getParticularFilterResultsCallReports(payload: any, id: string) {
    const url = `cdr/tenants/${getToken()}/report_filters/${id}`
    const result = get(url, payload)
    return result;
}

export async function removeFilterSearchCallReports(payload: any, id: string) {
    const url = `cr/tenants/${getToken()}/call_filters/${id}`
    const result = remove(url, payload)
    return result;
}

export async function getTenantDetails(tenant_uuid: any) {
    const url = `telco/${getToken()}/tenants/${tenant_uuid}`
    const result = get(url, {})
    return result;
}
export async function updateTenantStatus(payload: any, tenant_uuid: any) {
    const url = `tenants/${tenant_uuid}`
    const result = put(url, payload)
    return result;
}
export async function updateExtensionStatus(payload: any, tenant_uuid: any, extensionId: any) {
    const url = `tenants/${tenant_uuid}/extensions/${extensionId}`
    const result = put(url, payload)
    return result;
}

export async function updateDIDStatus(payload: any, tenant_uuid: any, did: any) {
    let url: any = ""
    if (did !== "") {
        url = `vrsa/tenants/${tenant_uuid}/dids/${did}`
    } else {
        url = `vrsa/tenants/${tenant_uuid}/dids`
    }
    const result = put(url, payload)
    return result;
}
export async function getStorageLocation(tenant_uuid: any,) {
    const url = `tenants/${tenant_uuid}/storage_location`;
    const result = get(url, {});
    return result;
}

export async function updateStorageLocation(
    payload: any,
    tenant_uuid: any,
) {
    const url = `tenants/${tenant_uuid}/storage_location`;
    const result = put(url, payload);
    return result;
}



export async function getExtensionsData(payload: { limit: number, pageId: number, sort_column: string, sort_direction: string, searchtext: string }, tenant_uuid: any) {
    let url: any = ""
    if ((payload.sort_column && payload.sort_direction) === "") {
        url = `tenants/${tenant_uuid}/extensions/fetch?limit=${payload.limit}&pageId=${payload.pageId}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`
    }
    else {
        url = `tenants/${tenant_uuid}/extensions/fetch?limit=${payload.limit}&pageId=${payload.pageId}&sort_column=${payload.sort_column}&sort_direction=${payload.sort_direction}${payload.searchtext != '' ? `&search=${payload.searchtext}` : ''}`

    }
    const result = get(url, {})
    return result;
}
export async function getNonMigratedTenants() {
    const url = `telco/${getToken()}/sil_tenants/fetch?add_tenant=true`
    const result = get(url, {})
    return result;
}
export async function getTimeZoneRestriction(tenant_uuid: any) {
    const url = `tenants/${tenant_uuid}/timezone_restriction`
    const result = get(url, {})
    return result;
}
export async function updateTimeZoneRestriction(payload: any, tenant_uuid: any) {
    const url = `tenants/${tenant_uuid}/timezone_restriction`
    const result = put(url, payload)
    return result;
}

// export async function addNonMigratedTenants(payload: any) {
//     const url = `tenants`
//     const result = post(url, payload)
//     return result;
// } 