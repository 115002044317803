import FadeIn from "react-fade-in";
import ComponentMap from "../../../atom/componentmap";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import { useState } from "react";
import Wallboard from "./wallboard";

function WallboardSettings() {
  const [launch, setLaunch] = useState<boolean>(false);
  const [wallBoardData, setWallBoardData] = useState<any>([
    {
      title: "Report Group",
      name: "reportGroup",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Vaspian", value: "Vaspian" },
        { label: "Vaspian2", value: "Vaspian2" },
      ],
      required: true,
      errormessage: "Report Group is Required",
    },
    {
      title: "Time Interval",
      name: "timeInterval",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [{ label: "Last Hour", value: "Last Hour" }],
      required: true,
      errormessage: "Time Interval is Required",
    },
    {
      title: "Include Incoming Calls",
      name: "incomingCalls",
      type: "checkbox",
      value: "",
    },
  ]);

  const onLaunch = () => {
    setLaunch(true);
  };
  return (
    <>
      {launch ? (
        <Wallboard />
      ) : (
        <div
          data-testid="wallboardSettings"
          className=" flex justify-center items-center h-[calc(100vh-110px)]"
        >
          <FadeIn>
            <div className="h-fit w-[520px] bg-[#FFFFFF] rounded-lg">
              <div className="text-[16px] font-bold px-7 py-4">
                Wallboard Settings
              </div>

              <div className="mt-4 relative px-5">
                <ComponentMap data={wallBoardData} setData={setWallBoardData} />
              </div>

              <div className=" w-full h-full py-4 flex items-baseline justify-end mt-4 -ml-6 ">
                <ThemedButton
                  children={"Launch"}
                  icon="custom"
                  theme="primary"
                  valiData={wallBoardData}
                  change={setWallBoardData}
                  click={onLaunch}
                />
              </div>
            </div>
          </FadeIn>
        </div>
      )}
    </>
  );
}
export default WallboardSettings;
