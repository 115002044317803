import React, { createContext, useEffect, useState } from "react";
import { Authv2 } from "./authv2";
import { tryLoginOptions } from "../../services/interfaces";
import { activityState } from "../../config/constants";
import Loader from "../loader";
import Loginv2 from "./login";
import { Store } from "react-notifications-component";
import { getUserPermission } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setPermissionStatus,
  setAccountId,
  setPermissions,
} from "../../toolkit/reducers/permissionSlice";
import { useNavigate } from "react-router-dom";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import { setGAccessToken } from "../../toolkit/reducers/authSlice";
import { setSimuation } from "../../toolkit/reducers/SimulationIdSlice";
// import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

interface AuthContextType {
  isAuthenticated: string;
  tokens: any;
  accessToken: string;
  refreshToken: string;
  timeOut: number;
  generatedTime: Number;
  signIn: (token: any, onSignInComplete: Function) => void;
  checkClient: (
    skipPermission: boolean,
    onCheckClientComplete: Function
  ) => void;
  signOut: (onSignOutComplete: Function) => void;
  syncCookie: (onSyncComplete: Function) => void;
  tryLogin: (options: tryLoginOptions, onLoginComplete: Function) => void;
}
export const AuthContext = createContext<AuthContextType>(null!);
var timer: any;
export function AuthClient({ children }: { children: React.ReactNode }) {
  const permissionStatus = useSelector(
    (state: any) => state.Permissions.status
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = React.useState<string>(
    activityState.LOADING
  );
  const [accessToken, setAccessToken] = React.useState<string>("");
  const [tokens, setTokens] = React.useState<any>({});
  const [refreshToken, setRefreshToken] = React.useState<string>("");
  const [timeOut, setTimeRefreshOut] = React.useState<number>(50000);
  const [generatedTime, setGeneratedTime] = React.useState<Number>(10000);
  let tryLogin = (options: tryLoginOptions, onSignInComplete: Function) => {
    setIsAuthenticated(activityState.WAIT);
    return Authv2.tryLogin(options, (status: boolean, result: any) => {
      if (status) {
        setTokens(result);
        setRefreshToken(result.refresh_token);
        const decode: any = jwtDecode(result.access_token);
        const value =
          decode?.tenant_id !== "undefined"
            ? decode?.tenant_id
            : decode.telco_id;
        dispatch(setAccountId(value));
        setTimeRefreshOut((result.expires_in - 10) * 1000);
        dispatch(setGAccessToken(result.access_token));
        setAccessToken(result.access_token);
        setAxios(result.access_token);
        setIsAuthenticated(Authv2.isAuthenticated);
        onSignInComplete(Authv2.isAuthenticated);
        setGeneratedTime(new Date().getTime());
        handlePermission();
        handleTokenCycle();
        navigate(`/app/routing`, { replace: true });
      } else {
        setIsAuthenticated(Authv2.isAuthenticated);

        Store.addNotification({
          title: "Failed",
          message: result,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false,
          },
        });
        onSignInComplete(activityState.ERROR);
      }
    });
  };
  const handlePermission = async () => {
    setTimeout(async () => {
      const data: any = await getUserPermission();
      var flag = false;
      var result: any = {};
      data.data?.map((item: any) => {
        Object.keys(item)?.map((key) => {
          flag = true;
          result[key] = item[key];
        });
      });
      // storePermission(result);
      dispatch(setPermissions(result));
      if (data?.status === 403 || flag === false) {
        dispatch(setPermissionStatus(403));
        navigate("app/unautherized", { replace: true });
      } else {
        dispatch(setPermissionStatus(data?.status));
      }
    }, 100);
  };
  let signIn = (token: any, onSignInComplete: Function) => {
    return Authv2.signIn(token, () => {
      setIsAuthenticated(activityState.SUCCESS);
      onSignInComplete(Authv2.isAuthenticated);
    });
  };
  let checkClient = (
    skipPermission: boolean = false,
    onCheckClientComplete: Function
  ) => {
    // debugger
    setIsAuthenticated(activityState.SILENTCHECK);
    return Authv2.checkClient((result: any) => {
      if (result?.access_token) {
        setIsAuthenticated(Authv2.isAuthenticated);
        setTokens(Authv2.token);
        setAxios(result.access_token);
        const decode: any = jwtDecode(result.access_token);
        const value =
          decode?.tenant_id !== "undefined"
            ? decode?.tenant_id
            : decode.telco_id;
        dispatch(setAccountId(value));
        dispatch(setGAccessToken(result.access_token));
        setAccessToken(result.access_token);
        setTimeRefreshOut((result.expires_in - 10) * 1000);
        setRefreshToken(result.refresh_token);
        setGeneratedTime(new Date().getTime());
        if (!skipPermission && Authv2.isAuthenticated) {
          handlePermission();
        }
        handleTokenCycle();
      } else {
        setIsAuthenticated(Authv2.isAuthenticated);
      }
      onCheckClientComplete(result.access_token);
    });
  };
  let syncCookie = (onCheckClientComplete: Function) => {
    return Authv2.syncCookie((result: any) => {
      setIsAuthenticated(Authv2.isAuthenticated);
      setTokens(Authv2.token);
      if (result?.access_token) {
        const decode: any = jwtDecode(result.access_token);
        const value =
          decode?.tenant_id !== "undefined"
            ? decode?.tenant_id
            : decode.telco_id;
        dispatch(setAccountId(value));
        dispatch(setGAccessToken(result.access_token));
        setAccessToken(result.access_token);
        setAxios(result.access_token);
        setRefreshToken(result.refresh_token);
        onCheckClientComplete(Authv2.isAuthenticated);
      } else {
        setTokens({});
        dispatch(setPermissionStatus(0));
        setIsAuthenticated(activityState.IDLE);
        dispatch(setSimuation(""));
        onCheckClientComplete(activityState.IDLE);
        dispatch(setAccountId(""));
        dispatch(setGAccessToken(""));
        setAccessToken("");
        setRefreshToken("");
        setGeneratedTime(0);
        navigate("app/", { replace: true });
      }
    });
  };
  let signOut = (onSignOutComplete: Function) => {
    setIsAuthenticated(activityState.WAIT);
    return Authv2.signOut(refreshToken, () => {
      setTokens({});
      dispatch(setPermissionStatus(0));
      setIsAuthenticated(activityState.IDLE);
      dispatch(setSimuation(""));
      onSignOutComplete(Authv2.isAuthenticated);
      dispatch(setAccountId(""));
      dispatch(setGAccessToken(""));
      setAccessToken("");
      setRefreshToken("");
      setGeneratedTime(0);
      if (timer) {
        clearTimeout(timer);
      }
    });
  };
  let value = {
    isAuthenticated,
    tokens,
    accessToken,
    refreshToken,
    timeOut,
    generatedTime,
    signIn,
    checkClient,
    signOut,
    syncCookie,
    tryLogin,
  };

  useEffect(() => {
    // Interceptor()
    checkClient(false, () => {});
  }, []);
  const onStorageUpdate = (event: StorageEvent) => {
    syncCookie(() => {});
  };
  const handleTokenCycle = () => {
    if (
      Authv2.isAuthenticated === activityState.SILENTCHECK ||
      Authv2.isAuthenticated === activityState.SUCCESS
    ) {
      console.log("handleTokenCycle", timeOut, Authv2.isAuthenticated);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        checkClient(true, () => {});
      }, timeOut);
    }
  };
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  const setAxios = (token: string) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    // axios.defaults.headers.common["credentials"] = 'omit';
    // axios.defaults.headers.common["cookie"] = '';
    // axios.defaults.headers.put["Authorization"] = "Bearer " + token;
    // axios.defaults.headers.delete["Authorization"] = "Bearer " + token;
    // axios.defaults.headers.get["Authorization"] = "Bearer " + token;
  };

  useEffect(() => {
    if (
      isAuthenticated === activityState.SUCCESS ||
      isAuthenticated === activityState.SILENTCHECK
    ) {
    } else {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    }
  }, [isAuthenticated]);
  // console.log("permissionStatus", permissionStatus)
  return (
    <AuthContext.Provider value={value}>
      {/* {permissionStatus}- {isAuthenticated} */}
      {/* <iframe src="http://localhost:3000/ncs" style={{ display: "none" }} id="shared"></iframe> */}
      {permissionStatus === 200 &&
      (isAuthenticated === activityState.SUCCESS ||
        isAuthenticated === activityState.SILENTCHECK) ? (
        children
      ) : isAuthenticated === activityState.LOADING ||
        (permissionStatus === 0 &&
          (isAuthenticated === activityState.SUCCESS ||
            isAuthenticated === activityState.SILENTCHECK)) ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <Loginv2 />
      )}
    </AuthContext.Provider>
  );
}
export function useAuth() {
  return React.useContext(AuthContext);
}
