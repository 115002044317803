import * as React from "react";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
const Datetimepicker = (props: any) => {
  const { data, change, node } = props;
  const handleChange = (nodess: any) => {
    // console.log("nodessnodessnodessnodess",nodess)
    let updateData = [...data];
    let filter;
    filter = updateData.filter((n) => {
      return n.name === node.name;
    });
    // debugger
    if (filter[0] && nodess && nodess.$d !== "Invalid date") {
      filter[0].value = moment(nodess?.$d).format("YYYY-MM-DD HH:mm:ss");
    } else if (nodess === null) {
      filter[0].value = ""
    }
    // console.log("searchResult",updateData)
    change([...updateData]);
  };
  const onkeydown = (e: any) => {
    e.preventDefault();
  }
  const checkMaxMinDate = (operator: string) => {
    return null
  }
  return (
    <div className="">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          // maxDate={moment(new Date()).toDate()}
          disabled={node.disabled || false}

          // label="disabled"
          // disabled={true}
          className={`${node.disabled ? "bg-[#aaaaaa1d]" : ""}`}
          minDate={checkMaxMinDate('>')}
          maxDate={checkMaxMinDate('<') || moment(new Date()).toDate()}
          renderInput={(params) => <TextField {...params} onKeyDown={(e: any) => {
            onkeydown(e)
          }} />}
          value={node?.value || null}
          label={node?.title}
          onChange={handleChange}

        />
      </LocalizationProvider>
    </div>
  );
};

export default Datetimepicker;
