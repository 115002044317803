const transalation = {
    AddressBook: "Directorio",
    CarrierManager: "Gerente de transporte",
    ControlManager: "Gerente de control",
    Dashboard: "Panel",
    Receptionist: "Recepcionista",
    SystemManager: "Administrador de sistemas",
    AccountManager: "Gerente de cuentas",
    RatesManager: "Gerente de Tarifas",
    NumberManager: "Administrador de números",
    Branding: "Marca",
    Users: "Usuarias",
    Groups: "Grupos",
    MainNumber: "Números",
    CallLogs: "Registros de llamadas",
    Devices: "Dispositivos",
    VoiceMail: "Mensaje de vozl",
    IVR: "IVR",
    NotificationManager: "Administrador de notificaciones",
    Settings: "Ajustes",
    Theme: "Tema",
    Language: "Idioma",
    SignOut: "desconectar",
    CompanyName: "nombre de empresa",
    AddressLine1: "Dirección Línea 1",
    AddressLine2: "Dirección Línea 2",
    EMail: "Correo electrónico",
    Cancel: "Cancelar",
    Save: "Ahorrar",
    Ok: "OK",
    AddNew_: "Agregar nueva {{key}}",
    No_Found: "No {{key}}",
    AccountName: "Account Name",
    Enable: "Permitir",
    Disable: "Desactivar",
    Actions: "Comportamiento",
    Invoices: "Facturas",
    AssignNumber: "Asignar número",
    AddCredit: "Añadir crédito",
    RemoveUser: "Quitar Usar",
    AddNewExtension: "Agregar nueva extensión",
    Unassign: "Desasignar",
    Getstartedbycreatinganew: "Comienza creando una nueva",
    PhoneNumbers: "Números de teléfono",
    No: "No",
    Label: "Etiqueta",
    search: "buscar",
    Update: "Actualizar",
    Assign: "Asignar",
    Date: "Fecha",
    Time: "Tiempo",
    From: "De",
    To: "A",
    Search: "Buscar",
    Numbers: "número",
    UpdatedSuccessfully: "{{key}} Updated Successfully",


    Control: {
        SomethingWentWrongPleaseTryAgain: "Algo salió mal. Por favor, vuelva a intentarlo",

        Users: {
            FirstName: "Nombre de pila",
            LastName: "Apellido",
            UserRole: "Roles del usuario",
            MainExtensionNumber: "Número de extensión principal",
            Extensions: "Extensiones",
            PhoneNumbers: "Números de teléfono",
            Devices: "Dispositivos",
            Features: "Características",
            AddToCompanyDirectory: "Agregar al directorio de la empresa",
            CallerIDNumber: "Número de identificación de llamadas",
            PleaseSelectCallerId: "Seleccione el identificador de llamadas",
            CallForwarding: "Reenvío de llamadas",
            HotDesking: "Escritorio compartido",
            Voicemails: "Correos de voz",
            MusicOnHold: "Música en espera",
            FindMeFollowMe: "Encuéntrame Sígueme",
            FaxBox: "Buzón de fax",
            MissedCallAlert: "Alerta de llamada perdida",
            LocalDialing: "Marcación local",
            SelectCallerId: "Seleccionar identificador de llamadas",
            ActiveFeatures: "Funciones activas",
            Password: "Contraseña",
            AddNewDevice: "Agrega un nuevo dispositivo",
            Email: "Correo electrónico",
            PleaseAssignNumberFirst: "PAsigne el número primero",
            AddToCountryDirectory: "Agregar al directorio de la empresa",
            CreateDeviceAutomatically: "Crear dispositivo automáticamente",
            ExtensionAddedSuccessfully: "Extensión agregada con éxito",
            ErrorWhileAddingExtensionPleaseTryAgain: "Error al agregar la extensión, intente nuevamente",
            ExtensionAlreadyExist: "La extensión ya existe",
            ExtensionUpdated: "Extensión actualizada",
            ExtensionRemovedSuccessfully: "Extensión eliminada con éxito",
            ErrorWhileRemovingExtensionPleaseTryAgain: "Error al eliminar la extensión, inténtalo de nuevo",
            ExtensionsMustContainExactlyDigits: "Las extensiones deben contener exactamente 4 dígitos.",
            InvalidFormat: "Formato inválido"


        },
        Groups: {
            Members: "miembros",
            Extensions: "Extensiones",
            PhoneNumbers: "Números de teléfono",
            Features: "Características",
            RingBack: "Volver a llamar",
            NextCall: "Próxima llamada",
            CallerIDPrepend: "Prefijo de identificador de llamadas",
            AddNewGroup: "Agregar nuevo grupo",
            Distribute: "Distribuir",
            ManageUser: "Administrar usuario",
            Group: "Grupo",
            GroupName: "Nombre del grupo",
            Unassign: "Desasignar",
            AddfromSpare: "Agregar de repuesto",
            Spare: "Repuesto",
            RemoveGroup: "Eliminar grupo",
            Search: "Buscar",

            GroupFeatures: {
                callerIdPrependUpdatedSuccesfully: "Prefijo de identificador de llamadas actualizado con éxito",
                ErrorInUpdatingCallerIdPrepend: "Error al actualizar el prefijo del identificador de llamadas"
            }

        },
        CallLogs: {
            All: "Toda",
            Incoming: "Entrante",
            Outgoing: "Extrovertida",
            MissedCall: "Llamada perdida",
            Extension: "Extensión",
            Duration: "Duración",
            Status: "Estado",
            CallRecordings: "Grabaciones",
            Start: "Comenzar",
            End: "Fin",
            Filter: "Filtrar",
        },
        Devices: {
            Type: "Tipo",
            Name: "Nombre",
            User: "Usuaria",
            Enable: "Permitir",
            AddFromSpare: "Agregar de repuesto",
            ChooseDevice: "Elegir dispositivo",
            PleaseSelectADevice: "Seleccione un dispositivo",
            DeviceUpdatedSuccessfully: "Dispositivo actualizado con éxito ",
            ErrorInUpdatingDevice: "Error al actualizar el dispositivo"






        },
        VoiceMail: {
            Name: "Nombre",
            User: "Usuaria",
            Number: "número"

        },
        Number: {
            labelUpdateSuccessMsg: "etiqueta actualizada con éxito",
            TwentyFourHours: "24 horas de oficina abierta",
            CustomHours: "Horario de oficina personalizado",
            SuccsesAssign: "Número asignado correctamente",
            SuccsesHoursUpdate: "Horario de oficina actualizado con éxito",
            SuccsesHoursAdd: "Horario de oficina agregado con éxito",
            SuccsesHolidaysUpdate: "Vacaciones de oficina actualizadas con éxito",
            SuccsesHolidaysAdd: "Vacaciones de oficina agregadas con éxito",
            OfficeHours: "Estrategia de horas de oficina",
            OfficeHolidays: "Vacaciones de oficina",
            IncomingCallHandling: "Manejo de llamadas entrantes",
            Open: "Abierto",
            Closed: "Cerrada",
            AddHolidays: "Agregar vacaciones",
            Single: "Día único",
            Range: "Rango de fechas",
            Advanced: "Avanzada",
            Month: "Mes",
            Day: "Día",
            Week: "Semana",
            HolidayName: "Nombre de vacaciones",
            From: "De",
            To: "A",
            OpenHours: "Horarios de apertura",
            AfterHours: "Fuera de horas",
            HolidayHours: "Horas de vacaciones",
            ClosedHours: "Horas cerradas",
            Error: "Error",
            AlreadyExist: "Number Already Exist."
        },
        PhoneNumbers: {
            AddFromSpare: "Agregar de repuesto"
        },
        Features: {
            RequireKeyPress: "Requerir pulsación de tecla",
            ForwardDirectCallOnly: "Reenviar solo llamada directa",
            KeepYourCallerId: "Mantenga su identificador de llamadas",
            HotdeskId: "Identificación de escritorio compartido",
            DoesItRequireAPin: 'Requiere un PIN?',
            AllowLoginToMultipleDevices: 'Permitir iniciar sesión en múltiples dispositivos',
            PleaseAssignNumberFirst: "Por favor asigne el número primero",
            EnableVoiceMailToEmail: "Habilitar correo de voz a correo electrónico",
            CallerIdNumberUpdatedSuccessfully: 'Caller Id Number Updated Successfully',
            ErrorInUpdatingCallerIdNumber: 'Error al actualizar el número de identificación de llamadas',
            Failed: "Fallido",
            UpdatedSuccessfully: "Actualizado con éxito",
            OnOff: "Encendido / apagado",
            FaxToEmail: 'Fax a correo electrónico',
            EmailToFax: 'Correo electrónico a fax',
            FaxToEmailIsRequired: "Se requiere fax a correo electrónico",
            EmailToFaxIsRequired: "Se requiere correo electrónico a fax",
            CallForwardingUpdatedSuccessfully: 'Desvío de llamadas actualizado con éxito'








        }
    },
    Number: {
        All: "Todo",
        Number: "número",
        Country: "País",
        Resellers: "Revendedores",
        Client: "Cliente",
        Status: "Estado",
        AddNewNumber: "Agregar nuevo número",
        Search: "Buscar",
        Unassign: "desasignar",
        Remove: "eliminar",
        AvailableNumber: "Número disponible",
        SpareNumber: "Número de repuesto",
        SelectCountry: "Seleccionar país",
        Assign: "Asignar",
        Cancel: "Cancelar",
        Add: "Agregar",
        Buy: "Comprar",
        Error: "Error",

        EnterNumber: "Ingresar número",
        ErrorCountryRequired: "El país es obligatorio.",
        ErrorNumberRequired: "El número es obligatorio.",
        Success: "Éxito",
        NotificationNumberAdd: "Número agregado con éxito",
        NotifiactionNumberbought: "Número comprado con éxito",
        AssignNumber: "Asignar número",
        UnassignModalData: "¿Estás seguro de desasignar número?",
        RemoveModalData: "¿Estás seguro de que quieres eliminar el número?",
    },
    Brand: {
        CompanyName: "nombre de empresa",
        AddressLine1: "Dirección Línea 1",
        AddressLine2: "Línea de dirección 2",
        Email: "Correo electrónico",
        Cancel: "Cancelar",
        Save: "Ahorrar",
        Logo: "Logo",
        FileTooltip: "El tamaño del archivo debe ser inferior a 5 MB.",
        ErrorUpload: "Cargue una imagen",
        ErrorCompanyName: "Introduzca el nombre de la empresa.",
        ErrorAddress: "Ingrese la dirección.",
        ErrorEmail: "Ingrese el correo electrónico."
    },
    Notification: {
        Subject: "Sujeto",
        Message: "Mensaje",
        Clear: "Claro",
        Submit: "Entregar",
    },
    Rates: {
        UploadRates: "Cargar tarifas",
        AddNewCountry: "AÑADIR NUEVO PAÍS",
        CountryName: "Nombre del país",
        CountryCode: "Código de país",
        Rate: "Tasa",
        Actions: "Comportamiento",
        ADD: "AGREGAR",
        SelectCountry: "Seleccionar país",
        Uploadordropafilerighthere: "Cargue o suelte un archivo aquí",
        Successfullyuploaded: "Cargado con éxito",
        UnsupportedFileError: "Archivo no admitido, cargue un archivo con extensión .CSV",
        DownloadsampleSheet: "Descargar hoja de muestra",
        AddRateSheet: "Agregar nueva hoja de tarifas",
        EnterNewRatesheet: "Introduzca el nombre de la hoja de tarifas",
        InputKeyword: "Ingrese la palabra clave y presione enter",
        RateDelete: "La tarifa ha sido eliminada con éxito",
        AddRate: "La tarifa ha sido añadida con éxito",
        UpdateRate: "La tarifa ha sido actualizada con éxito",
        AddedRateSheet: "La hoja de tarifas ha sido añadida con éxito",
        DownloadRateSheet: "Descargar hoja de tarifas",
        DeleteRateSheet: "Eliminar hoja de tarifas",
        RateSheetDeleted: "La hoja de tarifas ha sido eliminada con éxito",
        DeleteRate: "Tasa de eliminación",
        deleteRateModalData: "¿Estás seguro de que quieres eliminar Rate?",
        DeleteRatesheetModalData: "¿Estás seguro de que quieres eliminar Rateheet?",
        Delete: "Borrar"






    },
    Carrier: {
        Name: "Nombre",
        IPAddressDomain: "Dirección IP / dominio",
        Prefix: "Prefijo",
        Port: "Puerto",
        Priority: "Prioridad",
        UserName: "Nombre de usuario",
        Password: "Contraseña",
        ChooseCountries: "Elija países",
        AllCountries: "Todos los países",
        EnableFax: "Habilitar fax",
        SetSIPCustomHeaders: "Establecer encabezados SIP personalizados",
        CallerIdType: "Tipo de identificación de llamadas",
        ErrorPriority: "Se requiere prioridad",
        ErrorPassword: "se requiere contraseña",
        ErrorUserName: "Se requiere nombre de usuario",
        ErrorPort: "Se requiere puerto",
        ErrorPrefix: "Se requiere prefijo",
        ErrorIPAddressDomain: "Se requiere dirección IP / dominio válido",
        ErrorName: "Se requiere el nombre",
        SuccessfullMsg: "Portador agregado con éxito",
        ErrorMsg: "Huy! Algo salió mal !",
        UpdateMsg: "Transportista actualizado con éxito",
        Add: "Agregar nuevo portador",
        Remove: "eliminar",
        Search: "Buscar",
        Cancel: "Cancelar",
        Update: "Actualizar",
        Save: "Ahorrar",
        EmptyMsg: "No hay portadores",
        GetStartedMsg: "Comience creando un nuevo operador.",
        TryAgainMsg: "Por favor, inténtelo de nuevo más tarde.",
        RemoveMsg: "¿Estás seguro de que quieres eliminar",
    },
    DashBoard: {
        Users: "Usuarios",
        Devices: "Dispositivos",
        Numbers: "Números",
        Credits: "Créditos",
        TotalCalls: "Llamadas totales",
        ActivityLog: "Registro de actividades",
        Number: "Número",
        Who: "OMS",
        Where: "Dónde",
        CallCharges: "Cargos totales de llamadas",
        MinutesUsed: "Total de minutos utilizados",
        IncomingCalls: "Llamadas entrantes",
        MissedCalls: "Llamadas perdidas",
        OutgoingCalls: "Llamadas salientes",
        Calls: "Llamadas",
        Addusers: "Agregar usuarios",
        FirstName: "Nombre de pila",
        LastName: "Apellido",
        Email: "Correo electrónico",
        Password: "Contraseña",
        MainExtensionNumber: "Número de extensión principal",
        CreateDeviceAutomatically: "Crear dispositivo automáticamente",
        AddSipDevice: "Añadir dispositivo SIP",
        AddCellDevice: "Agregar dispositivo CELL",
        Name: "Nombre",
        AssignedTo: "Asignado a",
        notifyUnregister: "Notificar al darse de baja (no recomendado para usuarios de aplicaciones móviles).",
        autoProvision: "Aprovisionamiento automático",
        DeviceName: "Nombre del dispositivo",
        useSystemVoicemail: "Usar el correo de voz del sistema",
        BuyNumbers: "Comprar números",
        addCredit: "Añadir crédito",
        Amount: "Cantidad"
    },

    Account: {
        AccountName: "Nombre de la cuenta",
        ErrorName: "Por favor ingrese el nombre",
        Email: "Correo electrónico",
        ErrorEmail: "Por favor ingrese el correo electrónico",
        FirstName: "Nombre de pila",
        ErrorFirstName: "Por favor ingrese el primer nombre",
        LastName: "Apellido",
        ErrorLastName: "Por favor ingrese el apellido",
        ContactNumber: "Número de contacto",
        ErrorContact: "Ingrese el número de contacto",
        ChooseTimezone: "Elija TimeZone",
        ErrorTimeZone: "Elija una zona horaria",
        Address: "Dirección",
        ErrorAddress: "Ingrese la dirección",
        SuccessfullMsg: "Cuenta agregada con éxito",
        ErrorMsg: "Huy! Algo salió mal !",
        UpdateMsg: "Cuenta actualizada con éxito",
        AddNewAccount: "Agregar nueva cuenta",
        Actions: "Comportamiento",
        RemoveAccount: "Eliminar cuenta",
        Reseller: "Revendedoras",
        Account: "Cuenta",
        Plan: "Plan",
        Features: "Características",
        GetStartedMsg: "Comience creando una nueva cuenta.",
        TryAgainMsg: "Por favor, inténtelo de nuevo más tarde.",
        EmptyMsg: "Sin cuentas",
        ErrorCarrier:"Seleccione un transportista"

    }

}
export default transalation;