// counterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  value: string;
}

const initialState: MenuState = {
  value: "",
};

const activeSubMenu = createSlice({
  name: "subMenu",
  initialState,
  reducers: {
    setActiveSubMenu: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
      console.log(state.value, "state");
    },
  },
});

export const { setActiveSubMenu } = activeSubMenu.actions;
export default activeSubMenu.reducer;
