const transalation = {
    AddressBook: "Carnet d'adresses",
    CarrierManager: "Gestionnaire de transporteur",
    ControlManager: "Gestionnaire de contrôle",
    Dashboard: "Tableau de bord",
    Receptionist: "Réceptionniste",
    SystemManager: "Gestionnaire de système",
    AccountManager: "Gestionnaire de compte",
    RatesManager: "Gestionnaire des tarifs",
    NumberManager: "Gestionnaire de numéros",
    Branding: "l'image de marque",
    Users: "Utilisateurs",
    Groups: "Groupes",
    MainNumber: "Nombres",
    CallLogs: "Journaux d'appels",
    Devices: "Devices",
    VoiceMail: "Messagerie vocale",
    IVR: "RVI",
    NotificationManager: "Administrador de notificaciones",
    Settings: "Paramètres",
    Theme: "Thème",
    Language: "Langue",
    SignOut: "Se déconnecter",
    CompanyName: "Nom de l'entreprise",
    AddressLine1: "Adresse 1",
    AddressLine2: "Adresse 2",
    EMail: "Correo electrónico",
    Cancel: "Annuler",
    Save: "Sauvegarder",
    Ok: "d'accord",
    AddNew_: "Ajouter nouveau {{key}}",
    No_Found: "Non {{key}}",
    AccountName: "Nombre de la cuenta",
    Enable: "Activer",
    Disable: "Désactiver",
    Actions: "Actions",
    Invoices: "Factures",
    AssignNumber: "Attribuer un numéro",
    AddCredit: "Ajouter un crédit",
    RemoveUser: "Supprimer l'utilisateur",
    AddNewExtension: "Ajouter une nouvelle extension",
    Unassign: "Désattribuer",
    Getstartedbycreatinganew: "Comienza creando una nueva",
    PhoneNumbers: "Les numéros de téléphone",
    No: "Non",
    Label: "Étiqueter",
    search: "recherche",
    Update: "Mise à jour",
    Assign: "Attribuer",
    Date: "Date",
    Time: "Temps",
    From: "Depuis",
    To: "Pour",
    Duration: "Durée",
    Search: "Recherche",
    Numbers: "Nombre",
    UpdatedSuccessfully: "{{key}} Updated Successfully",


    Control: {
        SomethingWentWrongPleaseTryAgain: "Une erreur s'est produite. Veuillez réessayer",

        Users: {
            FirstName: "Prénom",
            LastName: "Nom de famille",
            UserRole: "Rôles d'utilisateur",
            MainExtensionNumber: "Numéro de poste principal",
            Extensions: "Rallonges",
            PhoneNumbers: "Les numéros de téléphone",
            Devices: "Dispositifs",
            Features: "Caractéristiques",
            AddToCompanyDirectory: "Ajouter au répertoire de l'entreprise",
            CallerIDNumber: "Numéro d'identification de l'appelant",
            CallForwarding: "Renvoi d'appel",
            HotDesking: "Bureau partagé",
            Voicemails: "Messages vocaux",
            MusicOnHold: "Musique en attente",
            FindMeFollowMe: "Trouve-moi, suis-moi",
            FaxBox: "Boîte de télécopie",
            MissedCallAlert: "Alerte d'appel manqué",
            LocalDialing: "Numérotation locale",
            SelectCallerId: "Sélectionnez l'identification de l'appelant",
            ActiveFeatures: "Fonctionnalités actives",
            Password: "Mot de passe",
            AddNewDevice: "Ajouter un nouvel appareil",
            Email: "E-mail",
            PleaseAssignNumberFirst: "Veuillez d'abord attribuer un numéro",
            AddToCountryDirectory: "Ajouter au répertoire de l'entreprise",
            CreateDeviceAutomatically: "Créer un appareil automatiquement",
            ExtensionAddedSuccessfully: "Extension ajoutée avec succès",
            ErrorWhileAddingExtensionPleaseTryAgain: "Erreur lors de l'ajout de l'extension, veuillez réessayer",
            ExtensionAlreadyExist: "L'extension existe déjà",
            ExtensionUpdated: "Extension mise à jour",
            ExtensionRemovedSuccessfully: "Extension supprimée avec succès",
            ErrorWhileRemovingExtensionPleaseTryAgain: "Erreur lors de la suppression de l'extension, veuillez réessayer",
            ExtensionsMustContainExactlyDigits: "Les extensions doivent contenir exactement 4 chiffres.",
            InvalidFormat: "Format invalide"

        },
        Groups: {
            Members: "Membres",
            Extensions: "Rallonges",
            PhoneNumbers: "Les numéros de téléphone",
            Features: "Caractéristiques",
            RingBack: "Rappeler",
            NextCall: "Prochain appel",
            CallerIDPrepend: "Préfixe de l'ID de l'appelant",
            PleaseSelectCallerId: "Veuillez sélectionner l'identifiant de l'appelant",
            AddNewGroup: "Ajouter un nouveau groupe",
            Distribute: "Distribuer",
            ManageUser: "Gérer l'utilisateur",
            Group: "Groupe",
            GroupName: "Nom de groupe",
            Unassign: "Désattribuer",
            AddfromSpare: "Ajouter à partir de la réserve",
            Spare: "De rechange",
            RemoveGroup: "Supprimer le groupe",
            Search: "Recherche",

            GroupFeatures: {
                callerIdPrependUpdatedSuccesfully: "L'identifiant de l'appelant a été mis à jour avec succès",
                ErrorInUpdatingCallerIdPrepend: "Erreur lors de la mise à jour de l'identifiant de l'appelant"
            }
        },
        CallLogs: {
            All: "Toute",
            Incoming: "Entrante",
            Outgoing: "Sortante",
            MissedCall: "Appel manqué",
            Extension: "Extension",
            Duration: "Durée",
            Status: "Statut",
            CallRecordings: "Enregistrements",
            Start: "Commencer",
            End: "Fin",
            Filter: "Filtre",
        },
        Devices: {
            Type: "Taper",
            Name: "Nom",
            User: "Utilisatrice",
            Enable: "Activer",
            AddFromSpare: "Ajouter à partir de la réserve",
            ChooseDevice: "Choisissez l'appareil",
            PleaseSelectADevice: "Veuillez sélectionner un appareil",
            DeviceUpdatedSuccessfully: "Appareil mis à jour avec succès",
            ErrorInUpdatingDevice: "Erreur lors de la mise à jour de l'appareil"





        },
        VoiceMail: {
            Name: "Nom",
            User: "Utilisatrice",
            Number: "Nombre"


        },
        Number: {
            labelUpdateSuccessMsg: "Étiqueter à jour avec succès",
            TwentyFourHours: "Bureau ouvert de 24 heures",
            CustomHours: "Heures de bureau personnalisées",
            SuccsesAssign: "Numéro attribué avec succès",
            SuccsesHoursUpdate: "Heures de bureau mise à jour avec succès",
            SuccsesHoursAdd: "Les heures de bureau ont ajouté avec succès",
            SuccsesHolidaysUpdate: "Les vacances de bureau ont mis à jour avec succès",
            SuccsesHolidaysAdd: "Les vacances de bureau ont ajouté avec succès",
            OfficeHours: "Stratégie des heures de bureau",
            OfficeHolidays: "Vacances au bureau",
            IncomingCallHandling: "Gestion des appels entrants",
            Open: "Ouvrir",
            Closed: "Fermée",
            AddHolidays: "Ajouter des vacances",
            Single: "Seule journée",
            Range: "Plage de dates",
            Advanced: "Avancée",
            Month: "Mois",
            Day: "Jour",
            Week: "Semaine",
            HolidayName: "Nom de vacances",
            From: "Depuis",
            To: "Pour",
            OpenHours: "Heures d'ouverture",
            AfterHours: "Après des heures",
            HolidayHours: "Heures de vacances",
            ClosedHours: "Heures fermées",
            Error: "Error",
            AlreadyExist: "Number Already Exist."
        },
        PhoneNumbers: {
            AddFromSpare: "Ajouter à partir de la réserve"
        },
        Features: {
            RequireKeyPress: "Nécessite d'appuyer sur une touche",
            ForwardDirectCallOnly: "Transférer l'appel direct uniquement",
            KeepYourCallerId: "Conservez votre identification de l'appelant",
            HotdeskId: "Identifiant de bureau partagé",
            DoesItRequireAPin: 'Nécessite-t-il un code PIN?',
            AllowLoginToMultipleDevices: 'Autoriser la connexion à plusieurs appareils',
            PleaseAssignNumberFirst: "Veuillez d'abord attribuer un numéro",
            EnableVoiceMailToEmail: "Activer la messagerie vocale par e-mail",
            CallerIdNumberUpdatedSuccessfully: "Numéro d'identification de l'appelant mis à jour avec succès",
            ErrorInUpdatingCallerIdNumber: "Erreur lors de la mise à jour du numéro d'identification de l'appelant",
            Failed: "Échoué",
            UpdatedSuccessfully: "Mis à jour avec succés",
            OnOff: "Allumé /éteint",
            FaxToEmail: 'Fax vers e-mail',
            EmailToFax: 'E-mail vers fax',
            FaxToEmailIsRequired: "Fax vers e-mail est requis",
            EmailToFaxIsRequired: "Courriel à télécopier est requis",
            CallForwardingUpdatedSuccessfully: "Transfert d'appel mis à jour avec succès"









        }
    },
    Number: {
        All: "Tout",
        Number: "Nombre",
        Country: "Pays",
        Resellers: "Revendeurs",
        Client: "Client",
        Status: "Statut",
        AddNewNumber: "Ajouter un nouveau numéro",
        Search: "Recherche",
        Unassign: "Désattribuer",
        Remove: "Retirer",
        AvailableNumber: "Numéro disponible",
        SpareNumber: "numéro de rechange",
        SelectCountry: "Choisissez le pays",
        Assign: "Attribuer",
        Cancel: "Annuler",
        Add: "Ajouter",
        Buy: "Acheter",
        Error: "Erreur",

        EnterNumber: "Entrer un nombre",
        ErrorCountryRequired: "Le pays est requis.",
        ErrorNumberRequired: "Le numéro est requis",
        Success: "Succès",
        NotificationNumberAdd: "Numéro ajouté avec succès.",
        NotifiactionNumberbought: "Nombre acheté avec succès",
        AssignNumber: "Attribuer un numéro",
        UnassignModalData: "Êtes-vous sûr de vouloir annuler l'attribution du numéro ?",
        RemoveModalData: "Voulez-vous vraiment supprimer le numéro ?",
    },
    Brand: {
        CompanyName: "Nom de l'entreprise",
        AddressLine1: "Adresse 1",
        AddressLine2: "Adresse 2",
        Email: "Email",
        Cancel: "Annuler",
        Save: "Sauvegarder",
        Logo: "Logo",
        FileTooltip: "La taille du fichier doit être inférieure à 5 Mo.",
        ErrorUpload: "Veuillez télécharger une image",
        ErrorCompanyName: "Veuillez saisir le nom de l'entreprise.",
        ErrorAddress: "Veuillez saisir l'adresse.",
        ErrorEmail: "Veuillez saisir un e-mail.",
    },
    Notification: {
        Subject: "Sujet",
        Message: "Message",
        Clear: "Clair",
        Submit: "Soumettre",
    },
    Rates: {
        UploadRates: "Taux de téléchargement",
        AddNewCountry: "AJOUTER UN NOUVEAU PAYS",
        CountryName: "Nom du pays",
        CountryCode: "Code postal",
        Rate: "Taux",
        Actions: "Actions",
        ADD: "AJOUTER",
        SelectCountry: "Choisissez le pays",
        Uploadordropafilerighthere: "Téléchargez ou déposez un fichier ici",
        Successfullyuploaded: "Téléversé avec succès",
        UnsupportedFileError: "Fichier non pris en charge, veuillez télécharger un fichier avec l'extension .CSV ",
        DownloadsampleSheet: "Télécharger la feuille d'exemple",
        AddRateSheet: "Ajouter une nouvelle feuille de tarifs",
        EnterNewRatesheet: "Saisissez le nom de la feuille de tarifs",
        InputKeyword: "Entrez le mot-clé et appuyez sur Entrée",
        RateDelete: "Le tarif a été supprimé avec succès",
        AddRate: "Le tarif a été ajouté avec succès",
        UpdateRate: "Le taux a été mis à jour avec succès",
        AddedRateSheet: "La feuille de tarifs a été ajoutée avec succès",
        DownloadRateSheet: "Télécharger la feuille de tarifs",
        DeleteRateSheet: " Supprimer la feuille de tarifs",
        RateSheetDeleted: "La feuille de tarifs a été supprimée avec succès",
        DeleteRate: "Taux de suppression",
        deleteRateModalData: "Voulez-vous vraiment supprimer Rate ?",
        DeleteRatesheetModalData: "Voulez-vous vraiment supprimer la feuille de taux ?",
        Delete: "Supprimer"


    },
    Carrier: {
        Name: "Nom",
        IPAddressDomain: "AdresseIp / Domaine",
        Prefix: "Préfixe",
        Port: "Port",
        Priority: "Priorité",
        UserName: "Nom d'utilisateur",
        Password: "Mot de passe",
        ChooseCountries: "Choisissez des pays",
        AllCountries: "Tous les pays",
        EnableFax: "Activer le fax",
        SetSIPCustomHeaders: "Définir les en-têtes personnalisés SIP",
        CallerIdType: "Type d'identification de l'appelant",
        ErrorPriority: "La priorité est requise",
        ErrorPassword: "Mot de passe requis",
        ErrorUserName: "Nom d'utilisateur est nécessaire",
        ErrorPort: "Le port est requis",
        ErrorPrefix: "Le préfixe est requis",
        ErrorIPAddressDomain: "L'adresse / domaine IP valide est requis",
        ErrorName: "Le nom est requis",
        SuccessfullMsg: "Carrier a ajouté avec succès",
        ErrorMsg: "Oups, quelque chose s'est mal passé!",
        UpdateMsg: "Carrier mis à jour avec succès",
        Add: "Ajouter un nouveau transporteur",
        Remove: "Retirer",
        Search: "Recherche",
        Cancel: "Annuler",
        Update: "Mise à jour",
        Save: "Sauvegarder",
        EmptyMsg: "Pas de transporteurs",
        GetStartedMsg: "Commencez par créer un nouveau transporteur.",
        TryAgainMsg: "Veuillez réessayer plus tard.",
        RemoveMsg: "Êtes-vous sûr de vouloir supprimer",
    },
    DashBoard: {
        Users: "Utilisateurs",
        Devices: "Dispositifs",
        Numbers: "Nombres",
        Credits: "Crédits",
        TotalCalls: "Appels totaux",
        ActivityLog: "Journaux d'activité",
        Number: "Nombre",
        Who: "OMS",
        Where: "Où",
        CallCharges: "Total des frais d'appel",
        MinutesUsed: "Nombre total de minutes utilisées",
        IncomingCalls: "Les appels entrants",
        MissedCalls: "Appels manqués",
        OutgoingCalls: "Les appels sortants",
        Calls: "Appels",
        Addusers: "Ajouter des utilisateurs",
        FirstName: "Prénom",
        LastName: "Nom de famille",
        Email: "E-mail",
        Password: "Mot de passe",
        MainExtensionNumber: "Numéro de poste principal",
        CreateDeviceAutomatically: "Créer un appareil automatiquement",
        AddSipDevice: "Ajouter un appareil SIP",
        AddCellDevice: "Ajouter un appareil CELL",
        Name: "Nom",
        AssignedTo: "Assigné à",
        notifyUnregister: "Notifier lors de la désinscription (non recommandé pour les utilisateurs d'applications mobiles).",
        autoProvision: "Provisionnement automatique",
        DeviceName: "Nom de l'appareil",
        useSystemVoicemail: "Utiliser la messagerie vocale du système",
        BuyNumbers: "Acheter des numéros",
        addCredit: "Ajouter un crédit",
        Amount: "Montant"
    },
    Account: {
        AccountName: "Nom du compte",
        ErrorName: "Veuillez saisir le nom",
        Email: "Email",
        ErrorEmail: "Veuillez saisir le courrier électronique",
        FirstName: "Prénom",
        ErrorFirstName: "Veuillez entrer le prénom",
        LastName: "Nom de famille",
        ErrorLastName: "Veuillez entrer le nom de famille",
        ContactNumber: "Numéro de contact",
        ErrorContact: "Veuillez saisir le numéro de contact",
        ChooseTimezone: "Choisissez le fuseau horaire",
        ErrorTimeZone: "Veuillez choisir un fuseau horaire",
        Address: "Adresse",
        ErrorAddress: "Veuillez saisir l'adresse",
        SuccessfullMsg: "Le compte ajouté avec succès",
        ErrorMsg: "Oups, quelque chose s'est mal passé!",
        UpdateMsg: "Compte mis à jour avec succès",
        AddNewAccount: "Ajouter un nouveau compte",
        Actions: "Actions",
        RemoveAccount: "Supprimer le compte",
        Reseller: "Revendeuse",
        Account: "Compte",
        Plan: "Plan",
        Features: "Caractéristiques",
        GetStartedMsg: "Commencez par créer un nouveau compte.",
        TryAgainMsg: "Veuillez réessayer plus tard.",
        EmptyMsg: "Pas de comptes",
        ErrorCarrier:"Veuillez sélectionner un transporteur"

    }
}

export default transalation;