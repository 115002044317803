import { useState } from "react";
import { useSelector } from "react-redux";
import Wallboard from "../wallboard/wallboard";
import UserPanel from "../userPanel/userPanel";
import ComponentMap from "../../../atom/componentmap";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import QueueInfo from "./queueInfo";
import AgentStatus from "./agentStatus";
import {
  CloseIcon,
  DownArrow,
  UpArrow,
  UserIcon,
} from "../../../utils/icons/defaultIcons";
import AgentInfoCard from "./agentInfoCard";
import WallboardSettings from "../wallboard/wallboardSettings";
import FadeIn from "react-fade-in/lib/FadeIn";

function QueueMonitor() {
  const [dropdownSelect, setDropdownSelect] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const activeLiveBoard = useSelector(
    (state: any) => state.ActiveBoard.activeMenu
  );

  const [queueGroupData, setQueueGroupData] = useState<any>([
    {
      title: "Queue Group",
      name: "queue",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [{ label: "Kev Test Thang", value: "Kev Test Thang" }],
    },
  ]);

  const text = [
    {
      queueInfo:
        "The main Queue Info window will show you the groups extension and name, as well as some statistics calculated for the last hour (last hour by default: different options are available upon launching). These are the meanings of each of the numbers.",
    },
    {
      agentInfo:
        "Agent info tells you how many agents are in the queue, how many of them are logged into the queue, and how many arecurrently on the phone. Please note that the On Phone count includes agents that may be on a call, but not logged into the queue group.",
    },
    {
      specificAgentInfo:
        "All agents in the queue will have an info-block, showing the Logged-In status, the Busy status and the Extension. The info-block which can be expanded by clicking to + sign on  the right side. This will show Current Activity and Activity  Summary If the agent is busy, Current Activity will show the  Calling Number, Dialed Number, and Start Time of the call the agent is currently on. Please note that this is real-time information, and may not a call related to / involving the Queue Group. Activity Summary provides a Call Count, Talk Time, and Average Talk Time for the agent for calls within the selected time frame. Please note these numbers are only of calls where the Queue Group was involved at some point in duration the call, and not an overall overview of the agent.",
    },
  ];

  const onSubmit = (result: any) => {
    setSubmit(true);
  };

  const count: any = [];
  for (let i = 0; i <= 10; i++) {
    count.push(i);
  }
  return (
    <div data-testid="queueMonitor">
      {activeLiveBoard === 1 ? (
        <WallboardSettings />
      ) : activeLiveBoard === 2 ? (
        <UserPanel />
      ) : (
        <div className=" h-full py-3 flex relative gap-2.5">
          {/* Side Div */}
          <div className="h-[calc(100vh-125px)] w-[24%] bg-[#FFFFFF] rounded-md px-1 py-2 space-y-2">
            <div className="font-bold py-4 px-2">Launch Queue Monitor</div>
            <ComponentMap data={queueGroupData} setData={setQueueGroupData} />
            <div className="text-right px-1">
              <ThemedButton
                children={"Submit"}
                theme={"primary"}
                click={onSubmit}
                change={setQueueGroupData}
                valiData={queueGroupData}
              />
            </div>
          </div>
          {/*  */}
          {/* main div */}
          <div className="h-[calc(100vh-125px)] w-[75%] bg-[#FFFFFF] rounded-md p-2 overflow-auto">
            <FadeIn>
              {submit ? (
                <div>
                  <FadeIn>
                    <p className="font-bold p-4">
                      {queueGroupData[0].childrens[0].value}
                    </p>
                    <div className="mt-2 border border-[#0000001F] rounded-lg">
                      <QueueInfo />
                    </div>
                    <div className="flex justify-center">
                      <div className="w-[30%] h-[45%] mt-6 border border-[#0000001F] rounded-lg">
                        <div className="flex justify-between items-center p-2">
                          <p>Agent Status</p>
                          <a className="cursor-pointer">
                            <CloseIcon />
                          </a>
                        </div>
                        <div className="-mt-2">
                          <AgentStatus />
                        </div>
                      </div>
                    </div>
                    <div className="w-full mt-4 px-20 grid xl:grid-cols-4 gap-2 md:grid-cols-3 sm: grid-cols-1 ">
                      {count?.map((node: any) => {
                        return (
                          <div key={node}>
                            <AgentInfoCard />
                          </div>
                        );
                      })}
                    </div>
                  </FadeIn>
                </div>
              ) : (
                <>
                  <p className="font-bold p-4">Launch Queue Monitor</p>
                  <p className="text-center">
                    Below you will find an example of the information you will
                    see in Queue Monitor.
                  </p>
                  <div className="flex justify-center w-full h-max mt-4">
                    <div className="border border-[#0000001F] w-[98%] rounded-lg p-2">
                      <div className="space-y-2">
                        <p className="font-semibold">Queue Info</p>
                        <p>{text[0]?.queueInfo}</p>
                      </div>
                      <div className="mt-2 border border-[#0000001F] rounded-lg ">
                        <QueueInfo />
                      </div>
                      <p className="bg-[#F7EFE7] w-full min-h-[50px]  flex items-center px-5 rounded-md mt-3">
                        <b> Call Count </b> : The number of calls within the
                        selected time frame where the group extension has
                        participated on a call at least once
                      </p>
                      <p className="mt-1">
                        Please note that all stats are updated around once every
                        5 minutes, except In Queue, which is real-time.
                      </p>
                    </div>
                  </div>

                  <div className="w-full h-[45%] mt-4 px-4">
                    <div className="flex justify-center h-full gap-5">
                      <div className="border border-[#0000001F] w-[98%] h-fit rounded-md p-2">
                        <p className="font-semibold">Agent Info</p>
                        <div className="w-[55%] h-[45%] mt-4 border border-[#0000001F] rounded-lg">
                          <div className="flex justify-between items-center p-2">
                            <p>Agent Status</p>
                            <a className="cursor-pointer">
                              <CloseIcon />
                            </a>
                          </div>
                          <div className="-mt-2">
                            <AgentStatus />
                          </div>
                        </div>
                        <p className="mt-2">{text[1]?.agentInfo}</p>
                      </div>
                      <div className="border border-[#0000001F] w-[98%] h-fit rounded-md p-2 overflow-auto max-h-[353px]">
                        <p className="font-semibold">Specific Agent Info</p>
                        {/* agent box */}
                        <div className="w-[46%]">
                          <AgentInfoCard />
                        </div>
                        {/*  */}
                        <p className="mt-3">{text[2]?.specificAgentInfo}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </FadeIn>
          </div>
          {/*  */}
        </div>
      )}
    </div>
  );
}

export default QueueMonitor;
