import MuiPhoneNumber from "material-ui-phone-number";
import countries from "../config/countriesList";

export function Phonenumber(props) {
  const { node, data, change } = props;

  const setChange = (value, details) => {
    let updateData = [...data];
    let filter;
    let cData;
    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    cData = countries.filter((n)=>{
      return n.name === details.name
    })

    let result = value.replace(/[- )(]/g, ""); 

    if (filter[0]) {
      filter[0].value = result;
      if (cData[0].co_code){
        filter[0].country = cData[0].co_code
      }else{
        filter[0].country = details.name
      }
    }
    change([...updateData]);
  };
  return (
    <div className="input">
      <MuiPhoneNumber
        defaultCountry={node.co_code !== "" ? node.co_code : "in"}
        variant="outlined"
        label={node.title}
        value={node.value}
        onChange={setChange}
        required={node.required}
        style={{ width: "100%" }}
        disabled={node.disabled}
        error={node.error}
      />
    </div>
  )
}
