import { useRef } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const BarGraph = (props) => {
  const chartRef = useRef();

  const handleClick = (event) => {
    console.log("handleClick");
  };

  console.log(props, "props");
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.label ? props.label : "",
        data: props.data,
        backgroundColor: props.color,
        hoverOffset: 4,
        borderWidth: props.border,
        barPercentage: 5,
        barThickness: 55,
        maxBarThickness: 55,
        minBarLength: 2,
        borderRadius: 6,
      },
    ],
  };
  const data2 = {
    labels: props.labels,
    datasets: [],
  };

  props?.dataArray?.forEach((props) => {
    data2.datasets.push({
      label: props.label ? props.label : "",
      data: props.data,
      backgroundColor: props.backgroundColor,
      hoverOffset: 4,
      borderWidth: props.border,
      barPercentage: 5,
      barThickness: 35,
      maxBarThickness: 30,
      minBarLength: 2,
      borderRadius: 6,
      // data: props.data,
    });
  });

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // This removes the x-axis gridlines
        },
      },
      y: {
        ticks: {
          callback: (value) => `${value / 5} hr`,
        },
        grid: {
          display: false, // This removes the x-axis gridlines
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  return (
    <Bar
      data={props.multipleBar === true ? data2 : data}
      ref={chartRef}
      onClick={handleClick}
      options={options}
    />
  );
};
export default BarGraph;
