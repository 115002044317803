import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
  provision: string;
}

const initialState: CallState = {
  provision: '',
};

const provision = createSlice({
  name: "provision", // Use a different name for the slice
  initialState,
  reducers: {
    activeProvision: (state, action: PayloadAction<string>) => {
      state.provision = action.payload;
    },
  },
});

export const { activeProvision } = provision.actions;
export default provision.reducer;
