import Textbox from "./textbox";
import "./componentmap.css";
import Timezone from "./timezone";
import RadioButtonsGroup from "./radio";
import CheckboxLabels from "./checkbox";
import Dropdown from "./dropdown";
import DatePickers from "./datepicker";
import { Multiselectdropdown } from "./multiselectdropdown";
import { InputTags } from "./inputTags";
import { Phonenumber } from "./phonenumber";
import Upload from "./input";
import ThemedButton from "./ThemedButton/themedButton";
import FadeIn from "react-fade-in/lib/FadeIn";
import CustomTooltip from "../atom/customTooltip";
import BlockHeader from "../atom/label";
import Datetimepicker from "./datetimePicker";
import TimePick from "./timepicker";
import TextLabel from "./textLabel";

const ComponentMap = (props) => {
  const {
    index,
    list,
    data,
    setData,
    reset,
    setRadioupdate,
    setTimeStamp,
    updatetimezone,
    radioupdate,
    handleChange,
    ValidationFlag,
  } = props;

  return (
    <div style={{ width: "100%" }}>
      {console.log(data, "data")}
      {data
        ?.filter((node, key) => {
          return node.hidden !== true || node.hidden === undefined;
        })
        .map((node, key) => {
          return (
            <div
              key={key}
              className={`
            inline-block p-2
            ${
              {
                1: "w-[16.6666667%]",
                2: "w-[33.3334%] max-sm:w-full",
                3: "w-[50%] max-sm:w-full",
                4: "w-[66.6668%] max-sm:w-full",
                5: "w-[83.3335%] max-sm:w-full",
              }[node.layout] || "w-full"
            }`}
            >
              <FadeIn>
                {/* <CustomTooltip title={!node.disabled && node?.error ? node.errormessage : ''}> */}
                <div>
                  {{
                    date: (
                      <DatePickers node={node} data={data} change={setData} />
                    ),
                    time: <TimePick node={node} data={data} change={setData} />,
                    checkbox: (
                      <CheckboxLabels
                        node={node}
                        data={data}
                        change={setData}
                      />
                    ),
                    timezone: (
                      <Timezone
                        node={node}
                        data={data}
                        change={setData}
                        updatetimezone={updatetimezone}
                      />
                    ),
                    dateTimePicker: (
                      <Datetimepicker
                        data={data}
                        change={setData}
                        node={node}
                      />
                    ),
                    radio: (
                      <RadioButtonsGroup
                        node={node}
                        setRadioupdate={setRadioupdate}
                        data={data}
                        change={setData}
                        radioupdate={radioupdate}
                      />
                    ),
                    Upload: <Upload node={node} data={data} change={setData} />,
                    phonenumber: (
                      <Phonenumber node={node} data={data} change={setData} />
                    ),
                    inputTags: (
                      <InputTags
                        node={node}
                        data={data}
                        index={index}
                        change={setData}
                      />
                    ),
                    dropdown: (
                      <Dropdown
                        node={node}
                        index={index}
                        data={data}
                        handleDDChange={handleChange}
                        change={setData}
                        list={list}
                      />
                    ),
                    multiselectdropdown: (
                      <Multiselectdropdown
                        node={node}
                        data={data}
                        index={index}
                        change={setData}
                      />
                    ),
                    label: <BlockHeader title={node.value} />,
                    textLabel: <TextLabel title={node.title} />,
                    themeButtons: (
                      <div className="p-1">
                        <hr className="m-1" />
                        <div className="flex justify-end mt-3">
                          {node.childrens &&
                            node.childrens.map((d, index) => {
                              return (
                                <ThemedButton
                                  key={index}
                                  children={d.title}
                                  valiData={d.validation ? data : []}
                                  change={setData}
                                  icon={d.icon}
                                  click={d.click}
                                  theme={d.theme}
                                />
                              );
                            })}
                        </div>
                      </div>
                    ),
                  }[node.type] || (
                    <Textbox
                      reset={reset}
                      node={node}
                      data={data}
                      change={setData}
                      setTimeStamp={setTimeStamp}
                      ValidationFlag={ValidationFlag}
                    />
                  )}
                </div>
                {/* </CustomTooltip> */}
              </FadeIn>
            </div>
          );
        })}
    </div>
  );
};

export default ComponentMap;
