const countries = [
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AD', name: 'Andorra', code: '376' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AE', name: 'United Arab Emirates', code: '971',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AF', name: 'Afghanistan', code: '93' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AG', name: 'Antigua and Barbuda', code: '1-268',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AI', name: 'Anguilla', code: '1-264' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AL', name: 'Albania', code: '355' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AM', name: 'Armenia', code: '374' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AO', name: 'Angola', code: '244' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AQ', name: 'Antarctica', code: '672' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AR', name: 'Argentina', code: '54' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AS', name: 'American Samoa', code: '1-684' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AT', name: 'Austria', code: '43' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AU', name: 'Australia', code: '61', suggested: true,},
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AW', name: 'Aruba', code: '297' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AX', name: 'Alland Islands', code: '358' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'AZ', name: 'Azerbaijan', code: '994' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BA', name: 'Bosnia and Herzegovina', code: '387',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BB', name: 'Barbados', code: '1-246' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BD', name: 'Bangladesh', code: '880' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BE', name: 'Belgium', code: '32' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BF', name: 'Burkina Faso', code: '226' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BG', name: 'Bulgaria', code: '359' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BH', name: 'Bahrain', code: '973' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BI', name: 'Burundi', code: '257' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BJ', name: 'Benin', code: '229' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BL', name: 'Saint Barthelemy', code: '590' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BM', name: 'Bermuda', code: '1-441' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BN', name: 'Brunei Darussalam', code: '673' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BO', name: 'Bolivia', code: '591' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BR', name: 'Brazil', code: '55' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BS', name: 'Bahamas', code: '1-242' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BT', name: 'Bhutan', code: '975' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BV', name: 'Bouvet Island', code: '47' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BW', name: 'Botswana', code: '267' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BY', name: 'Belarus', code: '375' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'BZ', name: 'Belize', code: '501' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CA', name: 'Canada', code: '1', suggested: true,},
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CC', name: 'Cocos (Keeling) Islands', code: '61',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CD', name: 'Congo, Democratic Republic of the', code: '243',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CF', name: 'Central African Republic', code: '236',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CG', name: 'Congo, Republic of the', code: '242',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CH', name: 'Switzerland', code: '41' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CI', name: "Cote d'Ivoire", code: '225' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CK', name: 'Cook Islands', code: '682' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CL', name: 'Chile', code: '56' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CM', name: 'Cameroon', code: '237' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CN', name: 'China', code: '86' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CO', name: 'Colombia', code: '57' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CR', name: 'Costa Rica', code: '506' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CU', name: 'Cuba', code: '53' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CV', name: 'Cape Verde', code: '238' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CW', name: 'Curacao', code: '599' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CX', name: 'Christmas Island', code: '61' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CY', name: 'Cyprus', code: '357' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'CZ', name: 'Czech Republic', code: '420' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DE', name: 'Germany', code: '49', suggested: true,},
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DJ', name: 'Djibouti', code: '253' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DK', name: 'Denmark', code: '45' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DM', name: 'Dominica', code: '1-767' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DO', name: 'Dominican Republic', code: '1-809',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'DZ', name: 'Algeria', code: '213' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'EC', name: 'Ecuador', code: '593' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'EE', name: 'Estonia', code: '372' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'EG', name: 'Egypt', code: '20' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'EH', name: 'Western Sahara', code: '212' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ER', name: 'Eritrea', code: '291' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ES', name: 'Spain', code: '34' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ET', name: 'Ethiopia', code: '251' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FI', name: 'Finland', code: '358' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FJ', name: 'Fiji', code: '679' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FK', name: 'Falkland Islands (Malvinas)', code: '500',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FM', name: 'Micronesia, Federated States of', code: '691',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FO', name: 'Faroe Islands', code: '298' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'FR', name: 'France', code: '33', suggested: true,},
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GA', name: 'Gabon', code: '241' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GB', name: 'United Kingdom', code: '44' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GD', name: 'Grenada', code: '1-473' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GE', name: 'Georgia', code: '995' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GF', name: 'French Guiana', code: '594' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GG', name: 'Guernsey', code: '44' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GH', name: 'Ghana', code: '233' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GI', name: 'Gibraltar', code: '350' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GL', name: 'Greenland', code: '299' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GM', name: 'Gambia', code: '220' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GN', name: 'Guinea', code: '224' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GP', name: 'Guadeloupe', code: '590' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GQ', name: 'Equatorial Guinea', code: '240' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GR', name: 'Greece', code: '30' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GS', name: 'South Georgia and the South Sandwich Islands', code: '500',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GT', name: 'Guatemala', code: '502' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GU', name: 'Guam', code: '1-671' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GW', name: 'Guinea-Bissau', code: '245' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'GY', name: 'Guyana', code: '592' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HK', name: 'Hong Kong', code: '852' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HM', name: 'Heard Island and McDonald Islands', code: '672',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HN', name: 'Honduras', code: '504' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HR', name: 'Croatia', code: '385' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HT', name: 'Haiti', code: '509' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'HU', name: 'Hungary', code: '36' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ID', name: 'Indonesia', code: '62' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IE', name: 'Ireland', code: '353' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IL', name: 'Israel', code: '972' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IM', name: 'Isle of Man', code: '44' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IN', name: 'India', code: '91' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IO', name: 'British Indian Ocean Territory', code: '246',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IQ', name: 'Iraq', code: '964' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IR', name: 'Iran, Islamic Republic of', code: '98',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IS', name: 'Iceland', code: '354' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'IT', name: 'Italy', code: '39' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'JE', name: 'Jersey', code: '44' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'JM', name: 'Jamaica', code: '1-876' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'JO', name: 'Jordan', code: '962' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'JP', name: 'Japan', code: '81', suggested: true,},
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KE', name: 'Kenya', code: '254' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KG', name: 'Kyrgyzstan', code: '996' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KH', name: 'Cambodia', code: '855' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KI', name: 'Kiribati', code: '686' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KM', name: 'Comoros', code: '269' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KN', name: 'Saint Kitts and Nevis', code: '1-869',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KP', name: "Korea, Democratic People's Republic of", code: '850',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KR', name: 'Korea, Republic of', code: '82' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KW', name: 'Kuwait', code: '965' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KY', name: 'Cayman Islands', code: '1-345' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'KZ', name: 'Kazakhstan', code: '7' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LA', name: "Lao People's Democratic Republic", code: '856',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LB', name: 'Lebanon', code: '961' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LC', name: 'Saint Lucia', code: '1-758' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LI', name: 'Liechtenstein', code: '423' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LK', name: 'Sri Lanka', code: '94' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LR', name: 'Liberia', code: '231' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LS', name: 'Lesotho', code: '266' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LT', name: 'Lithuania', code: '370' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LU', name: 'Luxembourg', code: '352' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LV', name: 'Latvia', code: '371' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'LY', name: 'Libya', code: '218' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MA', name: 'Morocco', code: '212' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MC', name: 'Monaco', code: '377' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MD', name: 'Moldova, Republic of', code: '373',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ME', name: 'Montenegro', code: '382' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MF', name: 'Saint Martin (French part)', code: '590',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MG', name: 'Madagascar', code: '261' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MH', name: 'Marshall Islands', code: '692' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MK', name: 'Macedonia, the Former Yugoslav Republic of', code: '389',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ML', name: 'Mali', code: '223' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MM', name: 'Myanmar', code: '95' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MN', name: 'Mongolia', code: '976' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MO', name: 'Macao', code: '853' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MP', name: 'Northern Mariana Islands', code: '1-670',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MQ', name: 'Martinique', code: '596' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MR', name: 'Mauritania', code: '222' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MS', name: 'Montserrat', code: '1-664' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MT', name: 'Malta', code: '356' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MU', name: 'Mauritius', code: '230' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MV', name: 'Maldives', code: '960' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MW', name: 'Malawi', code: '265' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MX', name: 'Mexico', code: '52' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MY', name: 'Malaysia', code: '60' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'MZ', name: 'Mozambique', code: '258' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NA', name: 'Namibia', code: '264' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NC', name: 'New Caledonia', code: '687' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NE', name: 'Niger', code: '227' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NF', name: 'Norfolk Island', code: '672' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NG', name: 'Nigeria', code: '234' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NI', name: 'Nicaragua', code: '505' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NL', name: 'Netherlands', code: '31' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NO', name: 'Norway', code: '47' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NP', name: 'Nepal', code: '977' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NR', name: 'Nauru', code: '674' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NU', name: 'Niue', code: '683' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'NZ', name: 'New Zealand', code: '64' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'OM', name: 'Oman', code: '968' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PA', name: 'Panama', code: '507' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PE', name: 'Peru', code: '51' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PF', name: 'French Polynesia', code: '689' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PG', name: 'Papua New Guinea', code: '675' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PH', name: 'Philippines', code: '63' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PK', name: 'Pakistan', code: '92' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PL', name: 'Poland', code: '48' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PM', name: 'Saint Pierre and Miquelon', code: '508',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PN', name: 'Pitcairn', code: '870' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PR', name: 'Puerto Rico', code: '1' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PS', name: 'Palestine, State of', code: '970',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PT', name: 'Portugal', code: '351' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PW', name: 'Palau', code: '680' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'PY', name: 'Paraguay', code: '595' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'QA', name: 'Qatar', code: '974' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'RE', name: 'Reunion', code: '262' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'RO', name: 'Romania', code: '40' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'RS', name: 'Serbia', code: '381' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'RU', name: 'Russian Federation', code: '7' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'RW', name: 'Rwanda', code: '250' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SA', name: 'Saudi Arabia', code: '966' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SB', name: 'Solomon Islands', code: '677' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SC', name: 'Seychelles', code: '248' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SD', name: 'Sudan', code: '249' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SE', name: 'Sweden', code: '46' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SG', name: 'Singapore', code: '65' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SH', name: 'Saint Helena', code: '290' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SI', name: 'Slovenia', code: '386' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SJ', name: 'Svalbard and Jan Mayen', code: '47',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SK', name: 'Slovakia', code: '421' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SL', name: 'Sierra Leone', code: '232' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SM', name: 'San Marino', code: '378' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SN', name: 'Senegal', code: '221' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SO', name: 'Somalia', code: '252' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SR', name: 'Suriname', code: '597' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SS', name: 'South Sudan', code: '211' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ST', name: 'Sao Tome and Principe', code: '239',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SV', name: 'El Salvador', code: '503' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SX', name: 'Sint Maarten (Dutch part)', code: '1-721',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SY', name: 'Syrian Arab Republic', code: '963',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'SZ', name: 'Swaziland', code: '268' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TC', name: 'Turks and Caicos Islands', code: '1-649',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TD', name: 'Chad', code: '235' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TF', name: 'French Southern Territories', code: '262',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TG', name: 'Togo', code: '228' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TH', name: 'Thailand', code: '66' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TJ', name: 'Tajikistan', code: '992' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TK', name: 'Tokelau', code: '690' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TL', name: 'Timor-Leste', code: '670' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TM', name: 'Turkmenistan', code: '993' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TN', name: 'Tunisia', code: '216' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TO', name: 'Tonga', code: '676' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TR', name: 'Turkey', code: '90' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TT', name: 'Trinidad and Tobago', code: '1-868',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TV', name: 'Tuvalu', code: '688' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TW', name: 'Taiwan, Republic of China', code: '886',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'TZ', name: 'United Republic of Tanzania', code: '255',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'UA', name: 'Ukraine', code: '380' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'UG', name: 'Uganda', code: '256' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'US', name: 'United States', code: '1',co_code:'USA', suggested: true },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'UY', name: 'Uruguay', code: '598' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'UZ', name: 'Uzbekistan', code: '998' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VA', name: 'Holy See (Vatican City State)', code: '379',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VC', name: 'Saint Vincent and the Grenadines', code: '1-784',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VE', name: 'Venezuela', code: '58' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VG', name: 'British Virgin Islands', code: '1-284',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VI', name: 'US Virgin Islands', code: '1-340',  },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VN', name: 'Vietnam', code: '84' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'VU', name: 'Vanuatu', code: '678' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'WF', name: 'Wallis and Futuna', code: '681' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'WS', name: 'Samoa', code: '685' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'XK', name: 'Kosovo', code: '383' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'YE', name: 'Yemen', code: '967' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'YT', name: 'Mayotte', code: '262' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ZA', name: 'South Africa', code: '27' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ZM', name: 'Zambia', code: '260' },
  { regex: "^(91)?([0-9]{10})$", flag_code: 'ZW', name: 'Zimbabwe', code: '263' },
];
export default countries