export const darkTheme = {
    primary: "#0076AD",
    primaryAlpha5: "",
    primaryAlpha10: "",
    primaryAlpha20: "",
    primaryAlpha30: "",
    primaryAlpha40: "",
    primaryAlpha50: "",
    primaryAlpha60: "",
    primaryAlpha80: "",
    primaryText: "#FFFFFF",
    secondary: "#333333",
    secondaryText: "#FFFFFF",
    ternary: '#222222',
    ternaryText: "#AAAAAA",
    hover: "rgba(255,255,255,0.1)",
    error: "rgba(255,0,0,1)",
    tableHeader: "",
    errorAlpha40: "rgba(255,0,0,0.4)",
};