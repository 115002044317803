import axios, { AxiosResponse } from "axios";
import { useAuth } from "./authClient";
import { useEffect, useState } from "react";
import { activityState } from "../../config/constants";
import { rejects } from "assert";
import { FaroLog } from "../../services/utilities";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Loader from "../loader";

const AuthInterceptor = (props: any) => {
  var APIConfig: any;
  var duplicateCheck: any;
  const auth = useAuth();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const onResponse = (response: AxiosResponse): AxiosResponse => {
    FaroLog({
      type: "api_call_response",
      message: response,
    });
    return response;
  };

  const onRequest = (response: any): any => {
    // faro.api.pushLog(["API Request"], { context: JSON.stringify(error.response) });
    return response;
  };

  const onRequestError = (error: any): any => {
    // faro.api.pushLog(["API Request"], { context: JSON.stringify(error.response) });
    return error;
  };
  const onResponseError = async (error: any) => {
    FaroLog({
      type: "api_call_response",
      message: error.response,
    });
    if (error.response?.status === 401 && duplicateCheck !== APIConfig.url) {
      return await new Promise((resolve, reject) => {
        auth.checkClient(true, async (accessToken: string) => {
          duplicateCheck = APIConfig.url;
          APIConfig.headers["Authorization"] = `Bearer ${accessToken}`;
          resolve(axios.request(APIConfig));
        });
      });
    } else {
      return Promise.reject(error);
    }
  };
  useEffect(() => {
    axios.interceptors.request.use((config) => {
      if (!config.url?.includes("/protocol/openid-connect/token")) {
        APIConfig = config;
        if (duplicateCheck !== APIConfig.url) {
          duplicateCheck = "";
        }
      }
      return config;
    });
    // const local:any = window.sessionStorage.getItem("simulation") || null
    const simulation = window.sessionStorage.getItem("simulation") || null;
    // const simulation = cookies.get("simulation");
    if (simulation) {
      window.sessionStorage.removeItem("simulation");
      // cookies.remove('simulation', { path: '/' })
      setTimeout(() => {
        setFlag(false);
      }, 200);
      navigate(`/app/routing`, { replace: true });
    } else {
      setFlag(false);
    }
    axios.interceptors.request.use(onRequest, onRequestError);
    axios.interceptors.response.use(
      (response) => onResponse(response),
      (error) => onResponseError(error)
    );
  }, []);
  return (
    <>
      {flag ? (
        <div className="w-full h-full flex flex-col justify-center">
          <Loader />
        </div>
      ) : (
        props.children
      )}
    </>
  );
};
export default AuthInterceptor;
