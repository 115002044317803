
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import "./textbox.css";

const DatePickers = (props) => {
  const { node, data, change } = props;

  const setChange = (value) => {
    value = moment(value).format('YYYY-MM-DDT00:00:00.000').toString();
    let updateData = [...data];
    let filter = updateData.filter((n) => {
      return n.name === node.name;
    });
    filter[0].value = value;
    change([...updateData])
  }

  return (
    <div >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker

          disabled={node.disabled ? node.disabled : false}
          maxDate={node.maxdate ? new Date(node.maxdate) : null}
          minDate={node.date ? new Date(node.date) : null}
          label={node.title + (node.required ? ' *' : '')}
          value={node.value}
          views={["year", "month", "day"]}
          InputProps={{ style: { borderColor: 'black' } }}
          onChange={(newValue) => {
            setChange(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} error={node.error} />
          )}
        />
      </LocalizationProvider>

    </div>
  );
};
export default DatePickers;
