import React, {useRef } from "react"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import {
  Doughnut,
} from "react-chartjs-2"
ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart = (props) => {
  const chartRef = useRef();

  const handleClick = (event) => {
    console.log("handleClick")
  }
    const data = {
      labels: props.labels,
      datasets: [
        {
          label: props.label,
          data: props.data,
          backgroundColor: props.color,
          hoverOffset: 4,
          cutout:"70%"
        },
      ],
    }
    const options = {
    plugins: {
      htmlLegend: {
        // ID of the container to put the legend in
        containerID: 'legend-container',
      },
      legend: {
        display: false,
      }
    }
  }
  return <Doughnut data={data} ref={chartRef} onClick={handleClick} options={options}  />
};
export default DoughnutChart
