import { PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(200, 0, 0, 0.8)',
        marginBottom: "-24px !important",
        color: 'white',
        fontSize: 11,
    },
    // "& .MuiTooltip-tooltip": { marginBottom: "-20px !important"}
}));
interface Params {
    title: string;
}
const CustomTooltip = (props: PropsWithChildren<Params>) => {

    return <LightTooltip  placement="top-end" TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }} title={props.title}>
        <div>
            {props.children}
        </div>
    </LightTooltip>
}
export default CustomTooltip