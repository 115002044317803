import { useEffect, useRef, useState } from "react";
import {
  CloseIcon,
  EyeIcon,
  TableSortIconDownArrow,
} from "../../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";
import ComponentMap from "../../../atom/componentmap";
import Table from "../../../atom/table";

function Wallboard() {
  const modalRef = useRef<HTMLInputElement>(null);
  const [hideDropdown, setHideDropdown] = useState<boolean>(false);

  const tableTitles = [
    { key: "data1", title: "Extension", icon: <TableSortIconDownArrow /> },
    {
      key: "data2",
      title: "Name",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data3",
      title: "Call Received",
      icon: <TableSortIconDownArrow />,
    },
    { key: "data4", title: "Call Made", icon: <TableSortIconDownArrow /> },
    {
      key: "data5",
      title: "Internal Call",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data6",
      title: "Talk Time",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data7",
      title: "Average Talk Time",
      icon: <TableSortIconDownArrow />,
    },
    { key: "data8", title: "Status", icon: <TableSortIconDownArrow /> },
  ];

  const [tableData, setTableData] = useState<any>([
    {
      data1: "114",
      data2: "Kevin Hurley",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "James Johny",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Kattrin Moine",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Nimmy Jincoy",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Kevin Hurley",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Koley Jain",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Jack Fronser",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Kevin Hurley",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Kevin Hurley",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
    {
      data1: "114",
      data2: "Kevin Hurley",
      data3: "8",
      data4: "2",
      data5: "3",
      data6: "2:32",
      data7: "1:38",
      data8: "online",
    },
  ]);

  const tableTitles2 = [
    { key: "data1", title: "Extension", icon: <TableSortIconDownArrow /> },
    {
      key: "data2",
      title: "First Name",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data3",
      title: "Last Name",
      icon: <TableSortIconDownArrow />,
    },
  ];

  const tableData2 = [
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
    {
      data1: "102",
      data2: "Mrs",
      data3: "Lopez",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains!(event.target)) {
        setHideDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideDropdown]);

  const [hideDropdownValues, setHideDropdownValues] = useState<any>([
    { name: "Direction", checked: false },
    { name: "Orginal Extension", checked: false },
    { name: "Transfer Extension", checked: false },
    { name: "Groups", checked: false },
    { name: "Call Organization", checked: false },
    { name: "Call Destination", checked: false },
    { name: "Start Time", checked: false },
    { name: "End Time", checked: false },
    { name: "Total Time", checked: false },
  ]);

  const [wallBoardData, setWallBoardData] = useState<any>([
    {
      title: "Name",
      name: "name",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Kevin Test", value: "Kevin Test" },
        { label: "Kevin Test2", value: "Kevin Test2" },
      ],
      layout: 3,
    },
    {
      title: "Day",
      name: "day",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Today", value: "Today" },
        { label: "Yesterday", value: "Yesterday" },
      ],
      layout: 3,
    },
  ]);

  return (
    <div data-testid="wallboard" className="space-y-3">
      {/*Top Bar  */}
      <div className="ml-[-12px] h-full">
        <div className="pt-3 px-3">
          <div className="bg-[white] h-[60px]  rounded-md">
            <div className="flex justify-between h-full px-4">
              <div className=" font-bold flex items-center -mt-0.5">
                {"Wallboard"}
              </div>
              <div className="smallPicker w-[20%] mt-0.5">
                <ComponentMap data={wallBoardData} setData={setWallBoardData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      {/* Body */}
      <div className="h-[calc(100vh-195px)] w-[calc(100vw-126px)] rounded-md gap-10">
        <div className="float-right w-[33.5%] h-[calc(100vh-197px)] bg-[#ffffff] rounded-md  py-3 px-3">
          <div className="flex justify-between">
            <p className="font-semibold"> Table</p>
            <p className="cursor-pointer">
              <CloseIcon />
            </p>
          </div>
          <Table
            tableTitles={tableTitles2}
            tableData={tableData2}
            width={"w-[calc(100vw-1330px)]"}
            scrollHeight={"max-h-[650px] overflow-y-auto"}
          />
        </div>
        <div className="flex gap-2 w-[66%] h-[45%]">
          <div className="w-full bg-[#ffffff] rounded-md p-3 space-y-8">
            <div className="flex justify-between">
              <p className="font-semibold"> Incoming Statistics</p>
              <p className="cursor-pointer">
                <CloseIcon />
              </p>
            </div>
            {/* Boxes */}
            <div className="flex justify-evenly">
              <div className="w-[162px] h-[70px] bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">254</p>
                <p className="font-semibold text-primary">Received</p>
              </div>
              <div className="w-[162px] h-[70px] bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">101</p>
                <p className="font-semibold text-primary">Answered</p>
              </div>
              <div className="w-[162px] h-[70px] bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">82%</p>
                <p className="font-semibold text-primary">Service Level</p>
              </div>
            </div>
            {/*  */}
            <div className="grid grid-cols-4 px-3 text-center">
              <div className="mt-8 space-y-2">
                <p>Wait</p>
                <p>Talk</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Total</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Average</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Maximum</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
            </div>
            <div className="text-center cursor-pointer">View More</div>
          </div>
          <div className="w-full bg-[#ffffff] rounded-md p-3 space-y-8">
            <div className="flex justify-between">
              <p className="font-semibold"> Outgoing Statistics</p>
              <p className="cursor-pointer">
                <CloseIcon />
              </p>
            </div>
            {/* Boxes */}
            <div className="flex justify-evenly">
              <div className="w-[30%] min-h-max h-max bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">000</p>
                <p className="font-semibold text-primary break-words">Made</p>
              </div>
              <div className="w-[30%] h-[70px] bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">000</p>
                <p className="font-semibold text-primary break-words">
                  Answered
                </p>
              </div>
              <div className="w-[30%] h-[70px] bg-[#E6F9F4] rounded-lg text-center py-2">
                <p className="font-bold">000</p>
                <p className="font-semibold text-primary break-words">
                  Service Level
                </p>
              </div>
            </div>
            {/*  */}
            <div className="grid grid-cols-4 px-3 text-center">
              <div className="mt-8 space-y-2">
                <p>Wait</p>
                <p>Talk</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Total</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Average</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold">Maximum</p>
                <p>00:00:00</p>
                <p>00:00:00</p>
              </div>
            </div>
            <div className="text-center cursor-pointer">View More</div>
          </div>
        </div>
        <div className="bg-[#ffffff] mt-2 w-[66%] rounded-md h-[calc(100vh-527px)]  p-3">
          <div className="flex justify-between">
            <p className="font-semibold"> Users(3)</p>
            <p className="cursor-pointer">
              <CloseIcon />
            </p>
          </div>
          <Table
            tableTitles={tableTitles}
            tableData={tableData}
            setTableData={setTableData}
            width={"w-[calc(100vw-750px)]"}
            scrollHeight={"max-h-[320px] overflow-y-auto"}
          />
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default Wallboard;
