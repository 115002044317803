import Close from "@mui/icons-material/Close";
import FadeIn from "react-fade-in/lib/FadeIn";
import * as React from "react";

const Modal = (props) => {
  const { icon, title, closeEvent, bgColour } = props;
  return (
    <div
      className="absolute  z-[10000]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-[#00000040] dark:bg-[#ffffff90] backdrop-blur-sm "></div>
      <div className="fixed inset-0 z-[100] overflow-y-auto">
        <div
          onClick={() => closeEvent()}
          className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <FadeIn>
            <div
              onClick={(e) => e.stopPropagation()}
              className="relative bg-[#FFFFFF]  animate transition-transform transform overflow-hidden  rounded-lg  text-left shadow-xl   min-w-[400px] min-h-[200px]"
            >
              {title && (
                <div
                  className={`p-3 flex justify-between ${
                    bgColour ?? "bg-[#FFFFFF]"
                  }`}
                >
                  <span className="flex">
                    <div className="pr-1 mr-2 mt-2 text-primary">
                      {icon ? icon : ""}
                    </div>
                    <div className="pt-[3px] text-[20px] font-semibold">
                      {title}
                    </div>
                  </span>
                  {closeEvent && (
                    <span
                      onClick={() => closeEvent()}
                      className="cursor-pointer"
                    >
                      <Close />
                    </span>
                  )}
                </div>
              )}
              <div className={` ${bgColour ?? "bg-[#FFFFFF] p-4"}`}>
                {props.children}
              </div>
            </div>
          </FadeIn>
        </div>
      </div>
    </div>
  );
};
export default Modal;
