export const baseTheme = {
  // primary: "#0076AD",
  primary: "#FEBA12",
  primaryAlpha5: "",
  primaryAlpha10: "",
  primaryAlpha20: "",
  primaryAlpha30: "",
  primaryAlpha40: "",
  primaryAlpha50: "",
  primaryAlpha60: "",
  primaryAlpha80: "",
  primaryText: "#FFFFFF",
  secondary: "#FFFFFF",
  secondaryText: "#222222",
  ternary: '#EEEEEE',
  ternaryText: "#222222",
  hover: "rgba(0,0,0,0.1)",
  error: "rgba(255,0,0,1)",
  tableHeader: "",
  errorAlpha40: "rgba(255,0,0,0.4)",
};