
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CallState {
    callName: number;
}

const initialState: CallState = {
    callName: 0,
};

const callRecordings = createSlice({
    name: "Call", // Use a different name for the slice
    initialState,
    reducers: {
        activeCallRecording: (state, action: PayloadAction<number>) => {
            state.callName = action.payload;

        },
    },
});


export const { activeCallRecording } = callRecordings.actions;
export default callRecordings.reducer;