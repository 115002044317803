import { useState } from "react";
import {
  DownArrow,
  UpArrow,
  UserIcon,
} from "../../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";

function AgentInfoCard(props: any) {
  const { open, setOpen } = props;
  const [dropdownSelect, setDropdownSelect] = useState<boolean>(false);

  const agentInfo = [
    {
      caller: "8948394838",
      dialed: "134",
      start: "10:02 AM",
      callCount: "10",
      talkTime: "00:00:00",
      avgTalkTime: "00:00:00",
    },
  ];
  return (
    <div data-testid="agentInfoCard">
      {/* agent box */}
      <div className=" h-fit mt-4">
        <div className="h-[67px] bg-[#FEF4E9] rounded-t-lg">
          <div className="flex justify-between p-3">
            <div className="flex gap-2">
              <div>
                <UserIcon />
                <p>000</p>
              </div>
              <div>
                <p>Jarvo</p>
                <div className="flex items-center space-x-2 w-[105%] rounded-lg px-1 bg-[#FBFBFF]">
                  <div className="bg-[#04C48E] rounded-full h-2.5 w-2.5"></div>
                  <div className="text-[#04C48E]">Online</div>
                </div>
              </div>
            </div>

            <div
              className="cursor-pointer"
              onClick={() => setDropdownSelect(!dropdownSelect)}
            >
              {dropdownSelect ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        </div>
        {dropdownSelect && (
          <div className="h-[130px] bg-[#E6F9F4]  rounded-b-lg">
            <FadeIn>
              <div className="grid grid-cols-3 py-1 w-full">
                {agentInfo?.map((node: any) => {
                  return (
                    <div key={node} className="mt-1 space-y-8 w-full px-3">
                      <div className="flex justify-between w-[248px] text-sm px-4">
                        <div>
                          <p className="text-center font-bold text-[13px] -ml-3.5 min-h-[20px]">
                            {node.caller}
                          </p>
                          <p className="text-[#888]">Caller</p>
                        </div>
                        <div className="-ml-8">
                          <p className="text-center font-bold text-[13px] min-h-[20px] ">
                            {node.dialed}
                          </p>
                          <p className="text-[#888]">Dialed</p>
                        </div>
                        <div>
                          <p className="text-center font-bold text-[13px] min-h-[20px]">
                            {node.callCount}
                          </p>
                          <p className="text-[#888]">Start</p>
                        </div>
                      </div>
                      <div className="flex justify-between w-[265px] text-sm">
                        <div>
                          <p className="text-center font-bold text-[13px] ">
                            {node.callCount}
                          </p>
                          <p className="text-[#888]">Call Count</p>
                        </div>
                        <div>
                          <p className="text-center font-bold text-[13px] ">
                            {node.talkTime}
                          </p>
                          <p className="text-[#888]">Talk Time</p>
                        </div>
                        <div>
                          <p className="text-center font-bold text-[13px] ">
                            {node.avgTalkTime}
                          </p>
                          <p className="text-[#888]">Avg Talk Time</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </FadeIn>
          </div>
        )}
      </div>
      {/*  */}
    </div>
  );
}

export default AgentInfoCard;
